@import "scss/variables";
@import "scss/theme/variables";

.VehicleCardVendor {
  display: flex;

  .VehicleCardVendor-details {
    min-width: 250px;
    max-width: 250px;
    min-height: 250px;

    .VehicleCardVendor-vehicleInfo {
      display: flex;
      align-items: center;
      justify-content: center;

      .VehicleCard-year-make-model {
        margin-left: $spacing-md;
        flex-grow: 1;
      }
    }

    .VehicleCard-details-table {
      margin: $spacing-sm 0;
    }

    .TagListSrp {
      height: 40px;
      flex-wrap: wrap;

      li {
        padding: 1px 0;
      }
    }

    .VehicleCardProgressBar {
      padding-top: $spacing-sm;
      height: 34px;
    }
  }

  .VehicleCard-invoiceTasks {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 250px;
    max-width: 450px;

    .VehicleCard-invoiceTasks-container {
      height: 195px;
      overflow-y: auto;

      .Vehicle-invoiceTasks-table {
        min-width: 250px;

        .MuiTableCell-sizeSmall {
          padding-left: 0;
        }

        .MuiTableCell-sizeSmall:last-child {
          padding-right: 0;
        }
      }

      .Vehicle-invoiceTasks-tableBold {
        font-weight: bold;
      }

      .Vehicle-invoiceTasks-tableNoBorder th,
      .Vehicle-invoiceTasks-tableNoBorder td {
        border-bottom-width: 0;
      }

      .FlatTask-status-estimated {
        color: $info;
      }

      .FlatTask-status-requested {
        color: $warning;
      }

      .FlatTask-status-approved,
      .FlatTask-status-completed {
        color: $success;
      }

      .FlatTask-status-denied {
        color: $muted;
      }

      .FlatTask-status-invoiced {
        color: #44c7ff;
      }

      .FlatTask-status-paid {
        color: #a0d66a;
      }
    }

    .Vehicle-invoiceTasks-buttonContainer {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;

      .Vehicle-invoiceTasks-createButton {
        margin-right: 12px;
      }

      .Vehicle-invoiceTasks-viewButton {
        margin-right: 12px;
      }

      .Vehicle-invoiceTasks-badgeContainer {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-end;
      }

      .VehicleCardTabFooter-badge {
        margin-left: 12px;
        position: relative;
        top: 4px;
      }
    }
  }

  .VehicleCardNotes {
    flex: 1;
  }

  .border-right {
    margin: -15px $spacing-md;
  }
}
