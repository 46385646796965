@import "../../../../scss/variables";
@import "../../../../scss/theme/variables";

$table-mode-width: 1470px;

.VelocityEngageCorporateReport {
  width: 100%;
  padding-bottom: 24px;

  .action-bar {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }

  .ReportContainer-panel-container {
    .engageOverview {
      flex-direction: column;
    }
  }

  .card {
    border: 1px solid rgb(0 0 0 / 12.5%);
    border-top: 0;

    @media (max-width: $table-mode-width) {
      border-top: 1px solid rgb(0 0 0 / 12.5%);
    }
  }

  .card-body {
    padding: 0;
  }

  .DropdownButton-selectedOption {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    color: $muted;
    text-transform: capitalize;
  }

  .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-header {
    padding: 0;
    border-bottom: 0;
    margin-bottom: 0;
    background-color: unset;
  }

  .VelocityEngageCorporateReport-header {
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  .VelocityEngageCorporateReport-title {
    font-family: OpenSans-Light, "Open Sans Light", "Open Sans", sans-serif;
    font-weight: $recon-medium;
    font-style: normal;
    font-size: 20px;
    padding: 18px 8px;
  }

  .VelocityEngageCorporateReport-overviewRow {
    display: flex;
    flex-flow: row wrap;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .VelocityEngageCorporateReport-SummaryCard-container {
    border: #e0e0e0 solid thin;
    background-color: white;
    box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
    flex-grow: 1;
    margin: 5px;
    min-width: 100px;
    max-width: 280px;

    .SummaryCard-base-stat-value-text {
      font-size: 20px;
    }

    .VelocityEngageCorporateReport-QuadrantCard {
      display: flex;
      flex-wrap: wrap;
      height: 100%;

      .VelocityEngageCorporateReport-Quadrant {
        width: 50%;
        text-align: center;
        align-content: center;

        h4 {
          margin: 0 0 5px;
        }

        .quadrant-good {
          color: $recon-success;
        }

        .quadrant-bad {
          color: $recon-error;
        }

        .quadrant-neutral {
          color: $recon-secondary;
        }
      }
    }
  }

  .column-flex-1-val {
    flex: 0 0 100px;
    padding: 15px 15px 15px 3px;

    @media (max-width: $table-mode-width) {
      flex-basis: 400px;
    }
  }

  .column-flex-3-val {
    flex: 0 0 190px;
    padding: 15px 15px 15px 3px;

    @media (max-width: $table-mode-width) {
      flex-basis: 400px;
    }
  }

  .column-flex-4-val {
    flex: 0 0 240px;
    padding: 15px 15px 15px 3px;

    @media (max-width: $table-mode-width) {
      flex-basis: 400px;
    }
  }

  .column-background {
    background: #f7f8f8;

    @media (max-width: $table-mode-width) {
      background: $recon-white;
    }
  }

  .evenly-spaced-container {
    @media (max-width: $table-mode-width) {
      flex-wrap: wrap;
    }
  }

  .row-multi-value-container {
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    font-weight: 400;
    padding-right: 3px;
  }

  .row-value-container {
    display: inline-flex;
    flex-direction: row;
    padding-right: 10px;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }

  .row-value-icon {
    color: $recon-icon-color;
    padding-right: 4px;
    padding-bottom: 3px;
  }

  .col-header-label {
    display: inline-flex;
    flex-direction: column;
    flex: 0 0 110px;
    font-weight: 600;
    justify-content: flex-end;
    padding-right: 10px;

    @media (min-width: $table-mode-width) {
      display: none;
    }
  }

  .col-value-container {
    display: inline-flex;
    flex-direction: column;
    flex: 0 0 60px;
    font-style: normal;
    font-size: 14px;
    justify-content: flex-end;
  }

  .col-value-label {
    color: $recon-secondary;
    font-size: 12px;

    @media (min-width: $table-mode-width) {
      display: none;
    }
  }

  .row-title-width {
    width: 19%;

    @media (max-width: $table-mode-width) {
      min-width: 200px;
    }
  }

  .row-content-width {
    width: 79%;
  }

  .summary-multi-value-container {
    display: flex;
    flex-flow: row wrap;
    font-style: normal;
    font-weight: 400;
    padding: 3px;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }

  .summary-value-container {
    display: inline-flex;
    flex-direction: row;
    padding-right: 10px;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }

  .summary-value-icon {
    color: $recon-icon-color;
    padding-right: 4px;
    padding-bottom: 3px;
  }

  .summary-col-value-container {
    display: inline-flex;
    font-size: 20px;
    flex-direction: column;
    padding-right: 10px;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }

  .summary-col-value-label {
    color: $recon-secondary;
    font-size: 14px;
  }

  .ReportTitleRow {
    padding: 15px 0 0 15px;
  }

  .ReportRow {
    padding: 0;
  }

  .ReportRow-title {
    padding: 15px 0;
  }

  .report-flex-columns {
    @media (max-width: $table-mode-width) {
      display: none;
    }
  }

  .not-enabled {
    text-align: center;
    color: $recon-secondary;
    font-size: 14px;
  }
}
