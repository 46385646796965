@import "../../../scss/theme/theme";

.Modal {
  .modal-content {
    padding: 0;
    border-radius: 3px;
    border: none;
  }

  .modal-body {
    padding: 0 1.5rem !important;
  }

  .modal-header {
    border: none;
    padding: 1.5rem 1.5rem 2rem;
  }

  .modal-title {
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: #263238;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .modal-footer {
    border-top: none;
    margin-top: 0;
    padding: 2rem 1.5rem 1rem;
  }

  .modal-footer > * {
    margin: 0;
  }

  .modal-dialog {
    max-width: 450px;
  }

  @media (width <= 575px) {
    .modal-dialog {
      margin: 1.75rem auto;
      max-width: 375px;
    }
  }
}
