// Colors
$brand-link-color: #d10;
$recon-primary: rgb(198 0 0 / 100%);
$recon-secondary: #37474f;
$recon-tertiary: #9e9e9e;
$recon-grey: #9e9e9e;
$recon-divider-color: #eff0f0;
$recon-page-background: #eaecec;
$recon-background-secondary: #263238;
$recon-background-tertiary: #f0f0f0;
$recon-success: #1ab245;
$recon-info: $recon-secondary;
$recon-error: #d50000;
$recon-error-light: rgb(198 0 0 / 20%);
$recon-white: white;
$recon-black: black;
$recon-pause-button: $recon-error;
$recon-complete-button: $recon-success;
$recon-alt-text: rgb(38 50 56 / 60%);
$recon-alt-text-6: rgb(38 50 56 / 6%);
$recon-alt-text-12: rgb(38 50 56 / 12%);
$recon-alt-text-38: rgb(38 50 56 / 38%);
$recon-ok: $recon-success;
$recon-warning: #ffab00;
$recon-danger: $recon-error;
$recon-disabled: #e0e0e0;
$recon-hover: #eee;
$recon-grey-border: #f2f2f2;
$recon-grey-summary: #f9f9fa;
$recon-dark-grey: #999;
$recon-tab-highlight: #c60000;
$recon-black-shadow: rgb(0 0 0 / 20%);
$recon-step-title-color: #868b8e;
$recon-dropdown-arrow-color: #b5b9ba;
$recon-icon-color: rgb(38 50 56 / 38%);
$vdp-menu-item-color: #fff;
$vdp-selected-menu-item-color: #eff1f1;
$recon-item-background-color: #f9fafa;
$recon-selected-item-background-color: #fff;

// Font
$recon-light: 200;
$recon-medium: 600;
$recon-bold: 700;
$recon-heavy: 900;

// Breakpoints
$screen-vdp-ipad: 1010px;
$screen-xl-min: 1920px;
$screen-lg-min: 1280px;
$screen-md-min: 960px;
$screen-sm-min: 600px;

// Theme values
$base-margin: 8px;

// Dimensions
$header-height: 60px;
$header-img-height: 40px;

// Spacing
$spacing-sm: 4px;
$spacing-md: 8px;
$spacing-lg: 16px;
$spacing-slg: 24px;
$spacing-xl: 32px;
$corner-radius: 4px;
$selected-border-width: 4px;

// Fonts
$recon-font-primary: "Source Sans Pro";
$recon-font-style: normal;
$recon-letter-spacing: 0.3px;
$recon-font-size: 14px;

@mixin VDPUpperCaseInfoTitle {
  font-family: $recon-font-primary;
  font-style: $recon-font-style;
  font-size: $recon-font-size;
  color: $recon-step-title-color;
  letter-spacing: $recon-letter-spacing;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}

@mixin VDPInfoValue {
  font-family: $recon-font-primary;
  font-style: $recon-font-style;
  letter-spacing: $recon-letter-spacing;
  color: $recon-background-secondary;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
}

$font-family-sans-serif: "Source Sans Pro", "Open Sans", sans-serif !default;
