@import "scss/variables";
@import "scss/theme/variables";

.PriceHistoryModal {
  display: flex;
  align-items: center;

  .modal-dialog {
    margin: $spacing-md auto;
  }

  .modal-content {
    margin: 0 $spacing-md;
    padding: $spacing-lg;

    .modal-header {
      padding: 0 0 $spacing-md 0;
      box-shadow: inset 0 -1px 0 #e6e7e8;
    }

    .modal-body {
      padding: 0 !important;
      max-height: 300px;
      overflow-y: auto;
    }

    .modal-footer {
      padding: $spacing-md 0 0 0;
    }
  }

  &-ctrl-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    &-text {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $primary;
      margin-right: 15px;
    }

    &-icon {
      color: $success;
    }
  }

  &-table {
    width: 100%;
  }

  &-header {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    height: 50px;
    box-shadow: inset 0 -1px 0 #e6e7e8;

    & > th {
      color: $muted;
    }
  }

  &-table-item {
    height: 50px;
    box-shadow: inset 0 -1px 0 #e6e7e8;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: $primary;
  }

  &-new-price {
    margin-right: 18px;
  }

  &-arrow-icon {
    padding-bottom: 1px;
  }
}
