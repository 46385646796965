@import "scss/variables";
@import "scss/theme/variables";

.VehicleCardExtension {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;

  &.VehicleCard2 {
    padding: 8px;
  }

  .VehicleCardExtension-vehicleInfo {
    display: flex;

    .VehicleImageView-showroom-size {
      height: 117px;
      width: 156px;
    }

    .VehicleCard-year-make-model {
      margin-left: $spacing-md;
      flex-grow: 1;
    }
  }

  .VehicleCard-details-table {
    margin: $spacing-sm 0;
  }

  .ProgressBar {
    margin: 4px 0;
  }

  .VehicleCard-recon-inventory-tabs {
    min-height: 200px;
    max-height: 200px;

    .VehicleCard-tab-container {
      .dropdown-toggle {
        height: 21px;
      }

      .VehicleCardTabFooter-container {
        margin-top: unset;
      }
    }

    .dropdown-menu.show {
      position: fixed !important;
      transform: unset !important;
    }

    .VehicleCardNotes {
      max-height: 165px;
    }

    .btn {
      height: 32px;
      padding: 0;
    }

    .VehicleCard-step-buttons-container {
      display: flex;

      button {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding: $spacing-md;
      }

      button + button {
        margin-left: $spacing-md;
      }
    }

    .VehicleCardTabFooter-Engage-buttons {
      margin-left: auto;
    }
  }
}
