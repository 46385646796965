@import "../../../scss/variables";
@import "../../../scss/mixins";

.Dashboard {
  &-content-container {
    max-width: 1557px !important;
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .Dashboard-welcome-container {
    margin-top: 0;
  }

  .Dashboard-panel-container {
    background-image: url("../../../Images/u111_state0.jpg");
    background-size: cover;
    background-position: center;
  }

  .Dashboard-salutation-container {
    margin-top: 30px;
    font-size: 28px;
    color: #fff;
    line-height: 30px;
  }

  .Dashboard-SOTU-container {
    padding-bottom: 26px;
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
    color: #fff;
    line-height: 30px;
  }

  .vehicle-attention-title-container {
    background-color: white;
    margin-top: 10px;
    margin-left: -15px;
    margin-right: -15px;
    padding-top: 1ch;
    padding-bottom: 1ch;
    border-style: solid;
    border-width: 1px 1px 1px 0;
    border-color: rgb(128 128 128 / 24.7%);
  }

  .vehicle-attention-title {
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: #37474f;
    text-align: left;
  }

  .vehicle-attention-grid {
    margin: $spacing-lg 0;
    padding-left: 8px;
    padding-right: 8px;

    @include xs {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .highlights-grid {
    margin-top: 28px;
    padding: 0;
    overflow-x: hidden !important;
  }
}

.highlights-row-container {
  margin-bottom: 32px;
  background-color: white;
  box-shadow: 0 4px 8px rgb(0 0 0 / 8%);
}

.Dashboard-tabs-container {
  z-index: 1;
}

.Dashboard-tab {
  z-index: 2;
}

.Dashboard-cardContainer {
  margin: 0 0 25px;

  .SummaryCard {
    padding: 10px;
  }
}

.Dashboard-summary-card-skeleton {
  height: 146px;
  background-color: white;
  border-radius: 5px;
}

.dashboard-notice-card {
  margin: 12px;
  padding: 0 !important;
  background: $recon-white;

  @include xs {
    margin: $spacing-sm;
  }
}

.no-padding {
  padding: 0;
}

.monthly-review-title-container {
  border-style: solid;
  border-width: 0 0 1px;
  border-color: rgb(128 128 128 / 24.7%);
  border-radius: 1px;
  padding: 16px 0 16px 24px;
}

.monthly-review-title-text {
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
}

.Dashboard-highlight-title-skeleton {
  margin: auto;
  height: 61px;
  background-color: white;
}

.Dashboard-highlight-graph-skeleton {
  margin: auto;
  height: 325px;
  background-color: white;
}

.Dashboard-highlight-card-skeleton {
  margin: auto;
  height: 86px;
  background-color: white;
}

.Dashboard-highlight-list-skeleton {
  margin: auto;
  height: 762px;
  background-color: white;
}

.Dashboard-no-notices-col {
  padding-bottom: 30px;
}

.Dashboard-no-notices-title {
  text-align: center;
  color: $recon-grey;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75;
}

.Dashboard-no-notices-message {
  text-align: center;
  color: $recon-tertiary;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
}

.dashboard-tab-icon {
  height: 20px;
  width: 27px;
}
