@import "scss/variables";
@import "scss/theme/variables";

.TaskStatus-container {
  text-transform: uppercase;

  .TaskStatus-estimated {
    color: $info;
  }

  .TaskStatus-requested {
    color: $warning;
  }

  .TaskStatus-approved,
  .TaskStatus-completed {
    color: $success;
  }

  .TaskStatus-denied {
    color: $muted;
  }

  .TaskStatus-invoiced {
    color: #44c7ff;
  }

  .TaskStatus-paid {
    color: #a0d66a;
  }
}
