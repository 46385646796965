@import "../../../../../scss/variables";
@import "../../../../../scss/mixins";

.AddVehicleModal {
  &-header-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: #263238;
    margin-top: -5px;
  }

  &-body {
    display: flex;
    flex-direction: row;
    margin-top: -30px;

    .container {
      padding: 0 !important;
    }

    &-column {
      border: 1px solid yellow;
    }

    &-checkbox {
      display: flex;
      flex-direction: row;
      height: 56px;
      justify-content: flex-start;
      align-items: center;
      margin-top: $spacing-lg;
      margin-bottom: $spacing-lg;
      padding: 10px;

      @media screen and (max-width: $screen-sm-min) {
        justify-content: center;
      }

      &-text {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: $recon-background-secondary;
        vertical-align: middle;
        margin-left: 65px;

        @media screen and (max-width: $screen-sm-min) {
          margin-left: 35px;
        }
      }

      &-box {
        bottom: -10px;
        left: -100px;
      }
    }

    &-top-row {
      margin-top: -10px !important;
    }

    &-view-button {
      border: none;
      color: inherit;
      background-color: transparent;
      padding-left: 0;
    }

    & .MuiPaper-root.MuiSnackbarContent-root {
      flex-flow: row nowrap;
    }
  }

  &-footer {
    border: none;
    margin: -5px;

    &-cancel-button {
      border: none;
      margin-right: 20px;
      color: $recon-background-secondary;
      font-family: $font-family-sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 1px;
      background-color: transparent;
    }

    &-save-button {
      width: 83px;
      height: 48px;
      border-radius: 3px;
      background: $recon-background-secondary;
      box-shadow: 0 4px 8px rgb(38 50 56 / 24%);
      font-family: $font-family-sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 1px;
      margin: 2px;
    }
  }

  .modal-dialog {
    max-width: 55%;
  }

  .SelectContainer,
  .TextInput,
  .Checkbox {
    margin: 16px 0;
  }

  @media screen and (max-width: $screen-md-min) {
    .modal-dialog {
      max-width: 85%;
    }
  }

  @media screen and (max-width: $screen-sm-min) {
    .modal-dialog {
      max-width: 95%;
    }
  }
}

@include xs {
  .AddVehicleModal.modal {
    padding-left: 0 !important;
  }
}
