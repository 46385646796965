@import "../../../scss/variables";
@import "../../../scss/mixins";

.page-container {
  background-color: $recon-secondary;
  padding-top: 16px;
  padding-bottom: 16px;
  height: 100vh;
  overflow-y: scroll !important;

  @include xs {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.form-container {
  padding: 32px 16px;
  background-color: $recon-white;
  display: flex;
  flex-direction: column;
  max-width: 600px !important;
  min-height: 600px !important;

  @include xs {
    height: 100%;
    justify-content: center;
  }
}

.change-logo {
  max-width: 100%;
  margin: 1em 1em 3em;
}

.submit {
  margin: 24px 0 16px !important;
}

.list-item {
  line-height: 1px !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.list {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  color: $recon-grey;
}
