@import "../../../../scss/variables";

.AddTasksModal {
  border-bottom: 1px solid #dadada;

  .modal-dialog {
    max-width: 550px;
  }

  .modal-body {
    padding: $spacing-md $spacing-slg !important;
  }

  .modal-header {
    border-bottom: 1px solid #dadada;
    padding: $spacing-md $spacing-slg;
  }

  .modal-footer {
    padding: 0;
    margin: 8px 24px;
  }

  &-body {
    display: flex;
    flex-direction: column;

    .AddTasksModal-FilterTextbox {
      border: 2px solid $recon-alt-text-38;
      border-radius: 4px;
      padding-left: $spacing-md;
      margin-bottom: $spacing-md;

      &:focus,
      &:active {
        outline: none;
      }
    }

    .AddTasksModal-TasksCounts {
      align-self: flex-start;
      margin-top: 4px;
    }

    .AddTasksModal-TaskTemplates {
      overflow: auto;

      .AddTasksModal-CustomTextbox {
        border: 2px solid $recon-alt-text-38;
        border-radius: 4px;
        padding-left: $spacing-md;
        width: 60%;

        &:focus,
        &:active {
          outline: none;
        }
      }
    }
  }

  &-row {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    padding: $spacing-sm 0;

    .Checkbox {
      margin-right: $spacing-xl;

      .AddTasksModal-checkbox {
        margin-top: -9px;
        border-color: $recon-alt-text-38;
      }
    }
  }

  &-TaskTemplates :nth-child(2n + 3) {
    background: $recon-alt-text-6;
  }

  &-task {
    flex: 1;
    display: flex;
    flex-direction: column;

    &-bottom {
      display: flex;
      color: gray;
      font-size: small;
      line-height: 90%;
    }

    &-tenantName {
      margin-right: 4px;
    }
  }
}
