.StepItem {
  cursor: pointer;
  position: relative;
  display: block;
  line-height: 40px;
  color: #444;

  &.top-step {
    font-weight: bold;
  }

  .item {
    cursor: pointer;
    margin-top: -1px;
    margin-bottom: -1px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 4px solid rgb(204 0 0 / 0%);
    padding-left: 18px;
    transition: border-left-color 0.5s;

    > {
      display: inline-block;
    }

    .truncate {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &:hover,
    &.selected {
      border-left-color: #c00;
      font-weight: bold;
    }

    &.selected {
      background: #fcfbf9;
    }
  }
}
