@import "../../../../scss/theme/variables";

.EngagementsTab {
  overflow-y: auto;

  &-table {
    width: 100%;
  }

  &-pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 28px 24px 24px;

    @media screen and (width <= 576px) {
      flex-direction: column-reverse;
      justify-content: flex-start;
    }

    &-info {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: $muted;
    }

    &-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-icon {
      cursor: pointer;
    }

    &-left-icon {
      margin-right: 40px;
    }

    &-count {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.3px;
      color: #000;
      margin-right: 24px;
    }
  }
}
