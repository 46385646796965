@import "../../../../scss/variables";

.CustomHeader-toast {
  position: absolute;
  bottom: 0;
  left: 24px;
  max-width: none !important;
  z-index: 5500;

  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 18px !important;

    &-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.3px;
      color: $recon-background-secondary;
      margin-right: 14px;
    }

    &-close-button {
      border: none;
      margin-left: 14px;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #d32f2f;
      background-color: transparent;
    }
  }
}
