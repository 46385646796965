@import "../../../../../scss/theme/variables";

.PriceInfo {
  &-price {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: $primary;
    margin-bottom: 8px;
  }

  &-label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: $primary;
  }

  &-info {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  &-sent-info {
    display: flex;
    flex-flow: row wrap;
  }

  &-btn {
    height: 24px;
    max-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 99px;
    padding: 3px 8px;
    margin-right: 16px;

    &-icon {
      font-size: 14px !important;
      margin-right: 11px;
      color: $light;
    }

    &-text {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $light;
      margin: 10px 0;
    }

    &-sent {
      background-color: $primary;
    }

    &-opened {
      background-color: #d50000;
      margin-right: 0 !important;
    }
  }
}
