@import "scss/variables";
@import "scss/theme/variables";

.TasksTable-container {
  background-color: #fff;

  .MuiTableCell-head {
    color: $recon-secondary;
    font-weight: 600;
    font-size: medium;
  }

  .TasksTable-container-vehicle {
    max-width: 225px;
    cursor: pointer;
  }

  .TasksTable-container-task {
    min-width: 200px;
  }

  .TasksTable-container-description {
    min-width: 200px;
  }

  .TasksTable-container-status {
    min-width: 100px;
  }

  .TasksTable-container-status-by {
    min-width: 125px;
  }

  .TasksTable-container-assigned-to {
    min-width: 125px;
  }

  .TasksTable-container-labor-rate {
    max-width: 100px;
  }

  .TasksTable-container-labor-hours {
    max-width: 100px;
  }

  .TasksTable-container-labor {
    max-width: 100px;
  }

  .TasksTable-container-parts {
    max-width: 100px;
  }

  .TasksTable-container-total {
    max-width: 100px;
  }

  .TasksTable-container-action {
    padding-left: 0;
    max-width: 30px;
  }

  .TasksTable-container-even-row {
    .MuiTableCell-root {
      background-color: $recon-background-tertiary;
    }
  }

  .TasksTable-VehicleInfo-container {
    display: flex;
    flex-direction: column;

    .TasksTable-VehicleInfo-year-make-model {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .TasksTable-VehicleInfo-vin {
      color: $recon-tertiary;
      font-size: small;
    }
  }
}
