@import "../../../../scss/variables";

.accordion {
  .RepairOrderItem-card {
    border-radius: initial;
    border-top: none;
    border-right: none;
    border-left: none;
    padding: $spacing-md;
    padding-left: $spacing-slg;
  }
}

.RepairOrderItem-header {
  display: flex;
  align-items: center;
}

.RepairOrderItem-icon {
  width: 28px;
}

.RepairOrderItem-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: $spacing-md;

  div {
    display: flex;
    flex-direction: row;
  }
}

.RepairOrderItem-prices {
  display: flex;
  flex: 1;

  .RepairOrderItem-price {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.RepairOrderItem-ro-number {
  font-weight: $recon-bold;
}

.RepairOrderItem-status {
  margin-left: $spacing-md;
}

.RepairOrderItem-status-open {
  margin-left: $spacing-md;
  color: $recon-success;
  font-weight: $recon-bold;
  text-transform: uppercase;
}

.RepairOrderItem-label {
  text-transform: uppercase;
  color: $recon-alt-text;
}

.RepairOrderItem-value {
  color: $recon-background-secondary;
}

.collapse {
  .RepairOrderItem-body {
    border-top: solid 1px $recon-alt-text-12;
    padding: 0;
    padding-top: $spacing-sm;
    margin-top: $spacing-md;
    flex: none;
  }
}
