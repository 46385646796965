@import "../../../../../scss/theme/variables";

.MostEngagedShoppers {
  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: $primary;
    margin-bottom: 8px;
  }

  &-empty-title {
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: $muted;
    margin-bottom: 8px;
  }

  &-table {
    width: 100%;

    & > tr:last-child > .MostEngagedShoppers-table-item {
      padding-bottom: 0 !important;
    }

    &-header {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: $primary;
      text-transform: uppercase;

      & > th {
        padding-bottom: 12px;
      }
    }

    &-item {
      padding-bottom: 8px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: $primary;
    }
  }

  &-small {
    margin-bottom: 16px;

    &-row {
      display: flex;
      flex-direction: row;
    }

    &-label {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: $primary;
      text-transform: uppercase;
      margin-right: 8px;
    }

    &-value {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: $primary;
      margin-right: 8px;
    }
  }
}
