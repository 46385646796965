@import "../../../scss/variables";

.ConfirmationDialog {
  .modal-dialog {
    max-width: 600px !important;
  }

  &__header {
    padding: 2rem 1rem 0 2rem !important;
  }

  &__no-header {
    padding: 0.5rem 0 0 !important;
  }

  &__modal_body {
    padding: 1rem 1rem 1rem 2rem !important;

    p {
      padding-right: 1rem !important;
    }
  }

  &__modal-title {
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: $recon-background-secondary;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ConfirmationDialog-create-button {
    padding: 0 12px;
    height: 48px;
    min-width: 68px;
    border-radius: 6px;
    background: $recon-background-secondary;
    box-shadow: 0 4px 8px rgb(38 50 56 / 24%);
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    margin: 2px;

    &:disabled {
      background-color: rgb(38 50 56 / 12%) !important;
      border-color: rgb(38 50 56 / 12%) !important;
      color: $recon-background-secondary !important;

      > div {
        opacity: 0.3;
      }
    }
  }

  .ConfirmationDialog-cancel-button {
    border: none;
    padding: 12px;
    margin-right: 10px;
    color: $recon-background-secondary;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    background-color: #fff;
  }

  .ConfirmationDialog-footer {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
  }
}
