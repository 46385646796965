@import "../../../../scss/variables";

.monthly-review-row {
  height: 86px;
}

.current-value-col {
  border-style: solid;
  border-width: 0 1px;
  border-color: rgb(128 128 128 / 24.7%);
}

.value {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 2px;
  text-align: center;
}

.difference-value-good {
  color: $recon-ok;
}

.difference-value-bad {
  color: $recon-danger;
}

.metric {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: rgb(38 50 56 / 60%);
  margin-top: 2px;
  text-transform: uppercase;
}
