@import "scss/variables";
@import "scss/theme/variables";

.VehicleCardProgressBar {
  min-height: 10px;

  .VehicleCard-step-duration-goal {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    overflow-x: hidden;
  }

  .Step-progress-title {
    text-transform: uppercase;
    text-overflow: ellipsis;
  }
}
