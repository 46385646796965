.TagFilter {
  display: flex;
  align-items: center;
  margin-left: 15px;
  white-space: nowrap;

  .TagFilter-select-container {
    margin-left: 15px;
  }
}
