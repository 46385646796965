@import "../../../../../scss/theme/variables";

.EngageMediaItem {
  padding-right: 24px;
  padding-bottom: 24px;

  &-info {
    position: relative;
    cursor: pointer;

    &-image {
      width: 200px;
      max-width: 200px;
      height: 129px;
      max-height: 129px;
      object-fit: cover;
      vertical-align: bottom;
      border-radius: 4px;

      &-overlay {
        position: absolute;
        inset: 0;
        background: linear-gradient(
          180deg,
          rgb(0 0 0 / 0%) 54.55%,
          rgb(0 0 0 / 90%) 100%
        );
        border-radius: 4px;
      }
    }

    &-title {
      position: absolute;
      bottom: 12px;
      left: 16px;
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.3px;
    }
  }

  &-icons {
    margin-top: 12px;

    .EngageMediaItem-icon {
      font-size: 20px !important;
      margin-right: 12px;
      color: rgb(38 50 56 / 38%);
      cursor: pointer;

      &:hover {
        color: $primary;
      }
    }
  }
}

.EngageMediaItemHorizontal-star-icon,
.EngageMediaItem-star-icon {
  color: #ffab00 !important;
}

.EngageMediaItemHorizontal-eye-icon,
.EngageMediaItem-eye-icon {
  color: $danger !important;
}

.EngageMediaItem.item {
  max-width: 224px;
}

.EngageMediaItemHorizontal {
  display: flex;
  flex-direction: row;

  &-image {
    width: 80px;
    max-width: 80px;
    height: 80px;
    max-height: 80px;
    object-fit: cover;
    vertical-align: bottom;
    border-radius: 3px;
  }

  &-info {
    margin-left: 12px;

    &-padding {
      padding-top: 10px;
    }
  }

  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: $primary;
  }

  &-icons {
    position: relative;
    margin-top: 10px;
  }

  .EngageMediaItemHorizontal-icon {
    font-size: 20px !important;
    margin-right: 12px;
    color: rgb(38 50 56 / 38%);
    cursor: pointer;

    &:hover {
      color: $primary;
    }
  }
}

.EngageMediaItemHorizontal-margin {
  margin-top: 8px;
  margin-bottom: 8px;
}

.EngageMediaItem-delete-modal {
  .modal-footer {
    justify-content: center !important;
    align-items: center !important;
    padding: 16px !important;
  }

  .modal-header {
    padding-left: 24px !important;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }

  .modal-dialog {
    width: 210px !important;
    min-width: 210px !important;
    max-width: 210px !important;
  }
}

.EngageMediaItem-edit-modal {
  .modal-footer {
    padding: 16px !important;
  }

  .modal-header {
    padding-left: 24px !important;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
  }

  .modal-dialog {
    width: 359px !important;
    min-width: 359px !important;
    max-width: 359px !important;
  }

  .file-select-button {
    border: 1px solid $primary !important;
  }

  .file-select-button:hover {
    border: 1px solid $primary !important;
  }
}
