@import "../../../scss/variables";
@import "../../../scss/mixins";

.page-container {
  background-color: $recon-secondary;
  margin: auto;
  height: 100vh;
  padding: 64px 0;

  @include xs {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.form-container {
  padding: 32px;
  background-color: $recon-white;
  display: flex;
  align-content: space-between;
  flex-direction: column;
  max-width: 600px !important;
  min-height: 600px !important;

  @include xs {
    height: 100%;
    justify-content: center;
  }
}

.reset-logo {
  max-width: 100%;
  margin: 1em 1em 3em;
}

.submit {
  margin: 24px 0 16px;
}
