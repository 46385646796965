@import "scss/variables";
@import "scss/theme/variables";

.VehicleCard-recon-inventory-tabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .VehicleCard-recon-inventory-tabs-container {
    display: flex;
    border-bottom: 1px solid rgb(38 50 56 / 12%);

    .VehicleCard-recon-inventory-tab-label {
      cursor: pointer;
      padding-bottom: 3px;
    }

    .VehicleCard-recon-inventory-tab-label.active {
      box-shadow: inset 0 -2px 0 #d32f2f;
    }
  }

  .VehicleCard-recon-inventory-tab-content {
    display: flex;
    flex-grow: 1;
    margin-top: $spacing-sm;

    .VehicleCard-tab-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .VehicleCard-engage-data {
        &-container {
          display: flex;
          margin-bottom: $spacing-md;
          flex-direction: column;

          .LabelValue-label {
            min-width: 155px;
          }
        }
      }

      .VehicleCardTabFooter-container {
        margin-top: auto;

        .engage-buttons {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .LabelValue-container {
        min-height: 20px;
        max-height: 20px;
      }

      .LabelValue-label {
        color: $recon-alt-text;
        min-width: 100px;
      }

      .LabelValue-value {
        .dropdown-toggle {
          padding: 0 !important;
        }
      }

      .Dropdown-btn.UsersDropdown {
        width: 100%;
      }

      .DropdownButton-selectedOption {
        font-size: $recon-font-size;
      }

      .StepButtons {
        display: flex;
        align-items: flex-end;
        overflow: hidden;
        margin: auto auto 0 0;

        .StepButtons-pause-col {
          padding-left: 0;
          padding-right: 0;

          button {
            width: 60.5px !important;
          }
        }

        .StepButtons-continue-col {
          padding-left: $spacing-sm;
          margin-right: 0;

          button {
            width: 60.5px !important;
          }
        }

        .simple-state-complete-button {
          width: 210px !important;
        }

        > button {
          display: unset !important;
          min-height: unset !important;
          max-height: unset !important;
          height: unset;
          padding: $spacing-sm $spacing-md;
          margin-left: auto;
          background-color: $recon-background-secondary !important;
          border-radius: 5px;

          span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .VehicleCardNotes {
      min-width: unset;
      max-width: unset;
    }
  }
}
