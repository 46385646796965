@import "scss/variables";

.MonthYearPickerModal {
  width: fit-content !important;
}

.MonthYearPickerModal .modal-content {
  padding: 10px;
}

.MonthYearPickerModal-body {
  padding: 0;
  box-shadow: 0 rgb(255 255 255 / 8%);
  display: flex;
}

.MonthYearPickerModal-footer {
  z-index: 1000;
  background-color: white;
  text-align: right;
  padding: 16px;
}

.MonthYearPickerModal-cancel-button {
  margin-right: 20px;
  border-width: 0;
}

.MonthYearPickerModal-ok-button {
  width: 64px;
  border-radius: 2px;
}

.MonthYearPickerModal .SelectContainer {
  margin: 20px;
  min-width: 200px;
  z-index: 10001;
}
