@import "../../../scss/variables";

.EditTagsModal {
  .EditTagsModal-body {
    padding-top: 0;
  }

  .EditTagsModal-footer {
    margin: -5px 2px;
  }

  .Select {
    .Select__control {
      height: 50px;
      border: 1px solid $recon-background-secondary;
      border-radius: 3px;
    }

    .Select__dropdown-indicator {
      color: rgb(38 50 56 / 38%);
    }
  }

  .EditTagsModal-cancel-button {
    border: none;
    margin-right: 20px;
    color: $recon-background-secondary;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    background-color: #fff;
  }

  .EditTagsModal-save-button {
    width: 83px;
    height: 48px;
    border-radius: 3px;
    background: $recon-background-secondary;
    box-shadow: 0 4px 8px rgb(38 50 56 / 24%);
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    margin: 2px;
  }

  .EditTagsModal-tags-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    font-size: 17px;
    padding: 0 0 10px;
  }
}
