@import "scss/variables";
@import "scss/theme/variables";

.StagedAttachments-container {
  display: flex;
  flex-wrap: wrap;

  .StagedAttachments-image {
    margin-right: $spacing-md;
    margin-bottom: $spacing-md;
    height: 100px;
    width: 100px;

    .UploadPlaceholder-overlay {
      border-radius: 8px;
      background: unset;
    }

    .UploadPlaceholder-image {
      border: 2px solid $recon-background-tertiary;
      border-radius: 8px;
      background-size: cover;
    }

    .UploadPlaceholder-cancel-button {
      border: 1px solid $recon-background-tertiary;
      border-radius: 8px;
    }
  }
}
