@import "../../../../scss/variables";

.TextInput {
  border: 1px solid #dadada;
  border-radius: 3px;
  display: flex;
  height: 56px;
  margin-top: $spacing-lg;
  margin-bottom: $spacing-lg;
  padding: 10px 12px;
  position: relative;

  &-adornment {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 5px;
  }

  &-loader {
    margin-top: 3px;
    text-align: right;

    .MuiCircularProgress-root {
      height: 25px !important;
      width: 25px !important;
    }
  }

  &-helper-text {
    color: #d50000 !important;
    margin-left: $spacing-md;
    margin-top: -8px;
  }

  &-input {
    border: none;
    color: $recon-background-secondary;
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 24px;
    width: 100%;

    &:focus {
      outline: none;
    }

    &:disabled {
      background-color: hsl(0deg 0% 95%);
      color: hsl(0deg 0% 50%);
    }
  }

  .floating-label {
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 24px;
    transition: 0.2s ease all;
    opacity: 0.5;
    background-color: transparent;
    padding: 0 2px;
    position: absolute;
    pointer-events: none;
    bottom: 15px;
    left: 15px;
  }

  input:focus ~ .floating-label,
  input:disabled ~ .floating-label,
  input:not(:focus):valid ~ .floating-label {
    font-size: 12px;
    left: 11px;
    bottom: 43px;
    background-color: $recon-white;
    opacity: 1;
    color: rgb(38 50 56 / 50%);
  }

  .floating-label-disabled {
    background: linear-gradient(
      to bottom,
      transparent 50%,
      hsl(0deg 0% 95%) 50%
    );
  }

  .floating-label-error {
    color: #d50000 !important;
    opacity: 1;
  }
}

.TextInput-disabled {
  background-color: hsl(0deg 0% 95%);
}
