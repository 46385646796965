@import "../../../scss/variables";
@import "../../../scss/mixins";

.CircularProgressChart {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  margin-bottom: 15px;

  &-text {
    padding-left: $spacing-lg !important;

    @include sm {
      padding-left: 12px !important;
    }
  }

  &-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #263238;
    white-space: nowrap;
  }

  &-time-info {
    white-space: nowrap;
  }

  &-time-logged {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: #263238;
    white-space: nowrap;
  }

  &-goal-time {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: rgb(38 50 56 / 60%);
    white-space: nowrap;
  }

  .circle-chart-background,
  .circle-chart-progress {
    fill: none;
  }

  .circle-chart-background {
    stroke: transparent;
  }

  .circle-chart-progress {
    stroke: #ffab00;
    stroke-linecap: butt;
    stroke-linejoin: butt;
  }

  .circle-chart-border {
    fill: none;
    stroke-width: 1;
    stroke: $recon-background-secondary;
  }

  .ok {
    stroke: $recon-ok;
  }

  .ontime {
    stroke: $recon-ok;
  }

  .warning {
    stroke: $recon-warning;
  }

  .danger {
    stroke: $recon-danger;
  }

  .late {
    stroke: $recon-danger;
  }
}
