@import "../../../scss/variables";

.SubmitFeedbackModal {
  .SubmitFeedbackModal-body {
    padding-top: 0;

    .version-info {
      font-size: 10px;
      color: darkgrey;
    }
  }

  .SubmitFeedbackModal-footer {
    margin: -5px;
  }

  .SubmitFeedbackModal-text-input {
    border: 1px solid $recon-background-secondary;
    border-radius: 3px;
    height: 50px;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    padding-left: 8px;
  }

  .SubmitFeedbackModal-form-label {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: rgb(38 50 56 / 60%);
    margin-top: 10px;
  }

  .SubmitFeedbackModal-textarea {
    height: 124px !important;
    margin-top: 32px !important;
  }

  .SubmitFeedbackModal-textarea-input {
    height: 104px !important;
  }

  .SubmitFeedbackModal-input-container {
    margin-top: 0;
  }

  .SubmitFeedbackModal-select-container {
    margin-bottom: 32px;
  }

  .SubmitFeedbackModal-cancel-button {
    border: none;
    margin-right: 20px;
    color: $recon-background-secondary;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    background-color: #fff;
  }

  .SubmitFeedbackModal-submit-button {
    width: 83px;
    height: 48px;
    border-radius: 3px;
    background: $recon-background-secondary;
    box-shadow: 0 4px 8px rgb(38 50 56 / 24%);
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    margin: 2px;

    &:disabled {
      background-color: rgb(38 50 56 / 12%) !important;
      border-color: rgb(38 50 56 / 12%) !important;
      color: $recon-background-secondary !important;

      > div {
        opacity: 0.3;
      }
    }
  }
}
