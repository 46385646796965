@import "../../../../scss/mixins";

.VehicleInfoItems {
  margin: 9px 32px 0 0;
  white-space: normal;
  flex-grow: 1;
  justify-content: space-between;

  .VehicleInfoItem-container {
    flex-wrap: wrap;
    max-width: 550px;

    .key-value {
      margin-right: 32px;
      margin-bottom: 10px;
      font-family: $recon-font-primary;
      font-size: $recon-font-size;

      @media only screen and (width >= 1008px) and (width <= 1036px) {
        margin-right: 28px;

        &:last-of-type {
          margin-right: 20px;
        }
      }

      @media only screen and (width >= 600px) and (width <= 700px) {
        margin-right: 18px;
      }

      @media only screen and (width >= 520px) and (width <= 600px) {
        margin-right: 12px;
      }

      .VehicleInfoItem-key {
        font-weight: bold;
        font-size: $recon-font-size;
        line-height: 18px;
        letter-spacing: 1px;
        text-transform: uppercase;
        opacity: 0.7;
        color: $recon-background-secondary;
      }

      .VehicleInfoItem-value {
        font-weight: normal;
        font-size: $recon-font-size;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: $recon-background-secondary;

        @media only screen and (width >= 520px) and (width <= 650px) {
          overflow-x: hidden;
          text-overflow: ellipsis;
          max-width: 75px;
        }

        .Dropdown .Dropdown-btn.dropdown .dropdown-toggle.btn {
          font-size: $recon-font-size !important;
          background-size: 8px 6px;
          background-position: right 8px;
        }

        .TextCopy-value {
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  @media screen and (min-width: $screen-vdp-ipad) {
    .VehicleInfoItem-container {
      max-width: unset;
    }
  }
}
