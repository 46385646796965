@import "../../../../scss/mixins";

.VehicleTitle {
  padding-top: 4px;
  line-height: 1.4;
  text-align: left;

  @include xs {
    padding-top: 16px;
  }

  .vehicle-title-back-button {
    position: absolute;
    top: 5px;
    left: $spacing-sm;
    z-index: 500;
  }

  .year-make-model {
    position: relative;
    width: 100%;

    @include xs {
      align-items: center;
    }
  }

  .year-make {
    font-size: 1rem;
    line-height: 1.2;
    text-align: left;
  }

  .model {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: left;
  }

  .title-col-2 {
    align-items: flex-end !important;
  }
}

.mobile-viewport .VehicleTitle {
  text-align: center;

  .year-make,
  .model {
    text-align: center;
  }
}
