@import "../../../scss/theme/variables";

.UploadManager {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 44px;
  cursor: pointer;

  &-icon-container {
    position: relative;
    width: 30px;
    height: 30px;

    .UploadManager-icon {
      height: 80%;
      width: 80%;
      fill: $success !important;
      z-index: 5500;
      position: absolute;
      top: 4px;
      left: 0;
    }

    .UploadManager-icon-background {
      background-color: $light !important;
      position: absolute;
      height: 10px;
      width: 10px;
      left: 7px;
      top: 12px;
    }
  }

  &-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $light;
    margin-top: 3px;
  }
}

.UploadManager-menu {
  position: absolute;
  width: 300px;
  background-color: $light;
  box-shadow: 0 8px 8px rgb(38 50 56 / 32%);
  border-radius: 3px;
  right: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  max-height: 252px;
  overflow-y: auto;

  &-mobile {
    left: -220%;
  }
}

.UploadManager-container {
  position: relative;
}
