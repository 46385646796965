@import "../../../scss/mixins";

.LoginScreenLayout {
  .image {
    background-image: url("../../../Images/red_racecar-100k.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .icon {
    max-width: 100%;
    max-height: 57px;
    margin-bottom: 8px;
  }

  .description {
    line-height: 1.3;
    margin-bottom: 48px;
  }

  .paper {
    margin: 64px 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form {
    max-width: 1280px; // Fix IE 11 issue.
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
  }

  .versionSummary {
    text-align: center;
    color: rgb(0 0 0 / 54%);
    font-size: 0.65rem;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    line-height: 1.43;
  }
}
