@import "../../../../scss/theme/variables";

.SidebarMobile,
.Sidebar {
  height: 100%;
  overflow-y: hidden;
  width: 320px;
  min-width: 320px;
  background-color: $primary;
  box-shadow: inset 0 1px 0 rgb(0 0 0 / 25%);

  .tab-content {
    height: calc(100% - 89px) !important;
  }

  #SRP-sidebar-tabpane-filters {
    height: 100%;
  }

  @media screen and (width >= 1024px) {
    width: 346px;
  }

  @media screen and (width >= 1367px) {
    width: 375px;
  }

  .Sidebar-tabs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: $primary;
    margin-left: 24px;
    margin-right: 24px;

    .nav-item {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.25px;
      color: rgb(255 255 255 / 70%);
      background-color: $primary;
      padding: 8px;
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: center;
    }

    .nav-item.active {
      color: #fff !important;
      background-color: $primary !important;
      border-bottom: 2px solid $danger !important;

      &:hover {
        border-bottom: 2px solid $danger !important;
      }
    }

    .nav-item:hover {
      border-color: $primary !important;
    }

    .Sidebar-tabs-overview-tab.active,
    .Sidebar-tabs-overview-tab,
    .Sidebar-tabs-overview-tab:hover {
      border-right: 1px solid rgb(0 0 0 / 16%) !important;
    }
  }

  @media screen and (width >= 1024px) {
    .toggle-sidebar {
      position: absolute;
      background-color: $info;
      border-radius: 14px;
      left: 362px;
      top: 20px;
      width: 24px !important;
      height: 24px;

      svg {
        color: #fff;
        cursor: pointer;
      }
    }
  }

  @media screen and (width <= 1366px) {
    .toggle-sidebar {
      left: 331px;
    }
  }
}

.SidebarMobile {
  position: fixed;
  inset: 0;
  overflow: scroll;
  background-color: $light;
  width: 100vw;
  height: calc(100%);
  z-index: 10000;

  &-hide {
    transform: translate3d(0, 100vh, 0);
  }

  &-show {
    transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
    transform: translate3d(0, 0, 0);
    overflow: hidden;
  }

  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: $primary;
    text-transform: capitalize;
    justify-self: center;
    background-color: $primary;
  }

  &-title-row {
    position: relative;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    box-shadow: 0 4px 8px rgb(0 0 0 / 8%);
  }

  &-close {
    position: absolute;
    left: 16px;
    fill: #fff !important;
  }

  // Since I'm sharing a lot of the styles between mobile/tablet and desktop
  // views, I need to disable the width on smaller screens
  .Sidebar {
    width: unset;
  }
}

@media screen and (width >= 1024px) {
  .hiddenSidebar {
    width: 12px !important;
    min-width: unset !important;
    overflow: hidden;

    .tab-pane {
      pointer-events: none !important;
    }

    .nav-item {
      opacity: 0;
    }

    .element::-webkit-scrollbar {
      appearance: none;
      width: 0;
      height: 0;
    }

    .toggle-sidebar {
      left: 2px;
      transform: scaleX(-1);
    }

    .step-items {
      overflow-y: hidden !important;
    }

    .item,
    .selected {
      border-left-color: rgb(0 0 0 / 0%) !important;
    }

    .FilterColumn-container {
      overflow-y: hidden !important;
    }
  }
}
