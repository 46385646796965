@import "../../../scss/mixins";
@import "../../../scss/theme/variables";

.FlatVDPPaneContent {
  background-color: $light !important;
  padding-left: 23px !important;
  padding-top: 24px !important;

  @include sm {
    padding-left: 15px !important;
  }

  @media screen and (width <= 575px) {
    padding-bottom: 115px;
  }
}
