@import "../../../../scss/variables";

.RepairOrderLine {
  display: flex;
  justify-content: space-between;
  padding: $spacing-sm 0 $spacing-sm 28px;

  .OpCode-and-OpCodeDescription {
    flex: 1;
    display: flex;
    margin-left: $spacing-md;

    .OpCode {
      flex: 1;
    }

    .OpCodeDescription {
      flex: 2;
    }
  }

  .prices {
    display: flex;
    flex: 1;

    .price {
      flex: 1;
    }
  }

  .parts-icon {
    width: 28px;
  }
}

.pad-right {
  padding-right: 28px;
}

.PartsUsed {
  display: flex;
  align-items: center;
  padding-left: $spacing-xl;
  padding-right: 28px;

  &-Table {
    margin: $spacing-lg 0;
    width: 100%;
    display: flex;
    flex-direction: column;

    &-row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > div > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.25rem;
        white-space: nowrap;
        overflow: hidden;
        line-height: 1.2rem;
        text-overflow: ellipsis;
      }

      .details {
        flex: 1;
        display: flex;

        .partNumber {
          flex: 1;
        }

        .description {
          flex: 2;
        }
      }

      .breakdown {
        display: flex;
        flex: 1;

        > div {
          flex: 1;
        }
      }
    }

    .header {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.25px;

      .details,
      .breakdown {
        > div {
          text-transform: uppercase;
          color: $recon-alt-text;
          border-bottom: 1px solid $recon-alt-text;
        }

        .placeholder {
          border: none !important;
        }
      }
    }
  }
}
