@import "../../../scss/variables";

$max-width: 180px;
$icon-size: 12px;
$right-padding: $icon-size + 2px;

.UsersDropdown.Dropdown-btn.dropdown {
  max-width: 140px;
}

@media screen and (width >= 800px) {
  .UsersDropdown.Dropdown-btn.dropdown {
    max-width: 200px;
  }
}

@media screen and (width >= 1000px) {
  .UsersDropdown.Dropdown-btn.dropdown {
    max-width: 300px;
  }
}

.UsersDropdown {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  text-overflow: ellipsis;

  .UsersDropdown-btn.dropdown {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    text-overflow: ellipsis;

    .dropdown-toggle.btn {
      max-width: 100%;
      overflow: hidden;
      background: url("../chevron-down.svg") no-repeat;
      background-size: $icon-size $icon-size;
      background-color: transparent;
      background-position: right 7px;
      border: none;
      padding: 0 $right-padding 0 0 !important;
      font-size: 16px !important;
      box-shadow: none;
      text-transform: none;
      text-overflow: ellipsis;

      svg {
        display: none;
        position: relative;
        top: -2px;
        margin: 0 !important;
      }

      .UsersDropdown-expand-icon {
        fill: $recon-dropdown-arrow-color;
      }

      &::after {
        border: none;
      }

      &:active {
        background-color: transparent !important;
      }

      &:focus {
        box-shadow: none;
      }

      &:disabled {
        color: #212529 !important;
        opacity: 1;
      }
    }
  }

  .UsersDropdown-btn.caret {
    display: none;
  }
}

.UsersDropdown-no-permission {
  .assigneeName {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
