@import "../../../scss/variables";

.IconButton {
  border: none !important;
  background-color: transparent !important;
  padding: 0 8px !important;

  & > * {
    color: $recon-alt-text;
  }
}
