@import "../../../../scss/variables";

$notification-icon-size: 32px;
$notification-status-dot-size: 6px;
$notification-padding: $spacing-md;

.NotificationListItem-notification {
  cursor: pointer;
  position: relative;
  padding: $notification-padding 54px $notification-padding 50px;
  border-bottom: 1px solid #eee;
  border-left: 4px solid #fff;
  line-height: 1.3;
  transition: border-left-color 0.3s;

  &:hover {
    border-left-color: $recon-primary;

    .NotificationListItem-status {
      pointer-events: none;
    }

    .NotificationListItem-actions {
      cursor: pointer;
      pointer-events: all;
      opacity: 1;
      border-radius: 4px;
      z-index: 5;
      background: #eee;
    }
  }

  .NotificationListItem-message {
    transition: opacity 0.5s;
  }

  &.is-deleting .NotificationListItem-message {
    opacity: 0.3 !important;
  }

  .NotificationListItem-actions,
  .NotificationListItem-status {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: $notification-icon-size;
    height: $notification-icon-size;
    text-align: center;
    right: $notification-padding;
    transition: opacity 0.3s;
  }

  .NotificationListItem-progress {
    padding-top: 4px;
  }

  .NotificationListItem-status-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: $notification-status-dot-size;
    width: $notification-status-dot-size;
    background-color: #f00;
    opacity: 1;
    pointer-events: all;
    border-radius: calc($notification-status-dot-size / 2);
  }

  .NotificationListItem-actions {
    pointer-events: none;
    opacity: 0;
    padding-top: 3px;
  }

  .NotificationListItem-task-actions {
    display: flex;
    justify-content: space-between;
    margin-top: $spacing-md;
  }
}
