@import "../../../scss/variables";
@import "../../../scss/theme/variables";

.MpiPane {
  &-Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-pin-button {
      background-color: transparent;
      border: none;
      padding: 0 !important;
    }

    &-print-button-icon {
      fill: rgb(158 158 158) !important;
    }

    @media screen and (max-width: $screen-sm-min) {
      button {
        position: absolute;
        left: 30px;
      }

      justify-content: center;
    }
  }

  &-StateTitle {
    @media screen and (max-width: $screen-sm-min) {
      width: 80%;
      text-align: center;
    }
  }

  .Mpi-radio-group-container {
    max-width: 1032px;
  }

  .Mpi-checklist-menu-item {
    padding: $spacing-md;
    padding-bottom: 75px;
  }

  .Mpi-clear-button {
    color: $danger;
    max-height: 40px;
    align-self: flex-end;
    padding-top: 0;
    padding-bottom: 0;
    background-color: unset;
    min-width: 60px;
  }

  .MpiSelect > .Select__control {
    border-color: hsl(0deg 0% 80%);
    max-width: 1032px;
    height: 40px;
    padding: 0;
  }

  .TextInput {
    max-width: 1032px;
    height: 40px;
    padding: 0;
    margin: 0;
  }

  .TextInput-input {
    padding: $spacing-md;
  }

  .radio-container {
    margin: 0;
  }

  @media screen and (max-width: $screen-md-min) {
    .Mpi-item-label {
      max-width: 320px;
    }
  }

  .progress {
    height: 4px;
  }
}

.Mpi-pinned-back-button {
  padding: 0 !important;
  padding-left: 28px !important;
  padding-top: 26px !important;
  border: none;
  background-color: transparent;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: $recon-background-secondary;

  &-icon {
    margin-right: 22px;
    color: $recon-alt-text;
  }
}

.MpiFooter {
  position: fixed;
  bottom: 0;
  background-color: $light;
  padding: $spacing-md;

  &-complete-label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: $muted;
  }

  &-completion-stats {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: $primary;
  }

  &-progress-container {
    margin-left: $spacing-slg;
    width: 248px;
  }
}

@media print {
  body {
    print-color-adjust: exact !important;
    writing-mode: horizontal-tb;
  }

  .MpiPane {
    position: absolute;
    inset: 0;
    width: 100vw;
    height: 100vh;
    overflow: visible;
  }

  .Mpi-checklist-menu-item,
  .MpiPane-body,
  .pane-body {
    overflow: visible !important;
  }

  .pane-body {
    background-color: #fff !important;
  }

  .MpiPane-Header-print-button-icon,
  .Mpi-clear-button,
  .MpiFooter,
  .pane-footer,
  .VehicleDetails-Menu,
  .VehicleInfoItems-dropdown,
  .TagList-icon,
  .Header {
    display: none !important;
  }

  .Mpi-checklist-menu-item {
    display: flex;
    flex-wrap: wrap;
  }

  .MpiCheckboxGroup,
  .MpiRadioGroup,
  .MpiSelect,
  .MpiTextInput,
  .field-container {
    flex-basis: 33%;
    padding-right: 15px;
    margin-bottom: 5px;
    page-break-inside: avoid;
  }
}
