@import "../../../scss/variables";
@import "../../../scss/theme/variables";

.MultipleSearchSelection {
  &-container {
    max-height: 200px;
    overflow-y: auto;
  }

  &-input {
    width: 100%;
    padding: 10px 0 10px 10px;
    border: 0;

    &:focus {
      outline: none !important;
    }
  }

  &-option {
    display: flex;
    flex-direction: row;
    padding: 10px 20px 10px 10px;
    max-width: 250px;
    cursor: pointer;

    &-title {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.3px;
      padding-left: 5px;
      color: #000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-checkbox {
      position: absolute;
      top: -9px;
      left: -1px;
    }
  }
}
