@import "../../../scss/variables";

.ReportRow {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  min-height: 40px;

  &.WithPadding {
    padding: 15px 0 15px 15px;
  }

  .selectable {
    cursor: pointer;
    text-decoration: underline;
  }

  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
    margin-left: -5px;
    margin-right: 5px;
  }

  .border-bottom {
    border-bottom: #646464 solid 1px;
  }

  .good {
    color: #00c761;
  }

  .bad {
    color: $recon-danger;
  }

  .overdue-idle {
    color: $recon-danger;
    font-weight: $recon-bold;
  }

  .ReportRow-title {
    font-weight: 500;
    font-size: medium;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
