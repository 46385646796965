@import "../../../../scss/variables";
@import "../../../../scss/theme/variables";

.VehicleStateFilter {
  display: flex;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

  button {
    text-transform: none;
  }
}
