@import "scss/variables";
@import "scss/theme/variables";

$tasks-tab-content-height: calc(100vh - 60px - 49px - 16px - 33px - 53px);

.Tasks-container {
  background-color: #fff;
  margin: $spacing-md 0;
  display: flex;
  flex-direction: column;

  .MuiTableContainer-root {
    height: $tasks-tab-content-height;
  }
}

.Tasks-loading {
  height: $tasks-tab-content-height;
}

.Tasks-no-data {
  display: flex;
  justify-content: center;
  height: $tasks-tab-content-height;
  padding-top: 20%;
  font-size: large;
  color: $recon-secondary;
  font-weight: 600;
}
