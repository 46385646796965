@import "../../../../scss/theme/variables";

.TaskPhotos {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;

  &-photos {
    display: flex;
    flex-direction: row;
    height: 32px;
    max-height: 32px;
  }

  .TaskPhotos-photo-container {
    cursor: pointer;
    margin-left: 2px;
    margin-right: 2px;
    min-width: 24px;
    width: 24px;
    height: 32px;

    &:first-child {
      margin-left: 0;
    }

    .TaskPhotos-photo {
      width: 24px;
      height: 32px;
      border-radius: 3px;
      object-fit: cover;
    }
  }

  .TaskPhotos-photo-container.TaskPhotos-add-photo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .MuiSvgIcon-root {
      fill: $muted;
      font-size: 12px;
    }
  }

  .TaskPhotos-add-photo-disabled {
    cursor: no-drop !important;
  }

  .TaskPhotos-photo-container.TaskPhotos-loading-photo {
    background-color: #eff1f1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
  }

  .TaskPhotos-photo-container.TaskPhotos-photo-container-truncated {
    position: relative;
    opacity: 1.4;

    .TaskPhotos-photo-count-overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      letter-spacing: 0.4px;
      color: #fff;
      border-radius: 3px;
      background: linear-gradient(rgb(0 0 0 / 50%), rgb(0 0 0 / 50%));
    }
  }

  .MuiCircularProgress-root {
    width: 15px !important;
    height: 15px !important;
  }
}
