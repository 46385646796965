@import "scss/variables";

// .ProgressBar {}

.ProgressBar-progress-bar {
  height: 4px;
  background: #f0f0f0;
  border-radius: 0;
  overflow: hidden;
  width: auto;
  margin: 4px 0 2px;
}

.ProgressBar-percentage {
  height: 100%;
  border-radius: 0;
  margin: 0;

  &.ok {
    background: $recon-ok;
  }

  &.warning {
    background: $recon-warning;
  }

  &.danger {
    background: $recon-danger;
  }

  &.ontime {
    background: $recon-ok;
  }

  &.late {
    background: $recon-danger;
  }
}

.ProgressBar-duration-values {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.ok-text {
  color: $recon-ok;
}

.warning-text {
  color: $recon-warning;
}

.danger-text {
  color: $recon-danger;
}
