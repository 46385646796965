@import "../../../../../scss/variables";
@import "../../../../../scss/mixins";

.VehicleMenuRow-flat {
  width: 100%;
  height: 44px;
  cursor: pointer;
  padding: 0;
  margin-bottom: 8px;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $vdp-menu-item-color;
  transition: background-color 0.5s;
  border-radius: 5px;
  align-items: center;
  align-self: flex-start;
  align-content: flex-start;

  &.selected {
    background-color: $vdp-selected-menu-item-color;
  }

  &:hover {
    background-color: $vdp-selected-menu-item-color;
  }

  .VehicleMenuRow-icon {
    width: 22px;
    height: 22px;
    margin: 0 auto;
  }

  .oversized-icon {
    width: 34px;
    height: 34px;
    margin: 0 0 0 6px !important;
  }

  .VehicleMenuRow-label {
    flex-grow: 1;
    height: 25px;
    margin-left: 16px;
    color: $recon-background-secondary;
    font-family: $recon-font-primary;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 24px;
    text-wrap: none;

    svg {
      margin: 4px;
    }
  }

  .VehicleMenuRow-chip {
    .MuiChip-root {
      height: 24px;

      .MuiChip-label {
        padding: 0 8px;
      }
    }
  }

  .menu-items-icon {
    width: 22px;
    height: 22px;
    color: $recon-background-secondary;
    margin: 0;
  }
}
