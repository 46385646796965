@import "../../../scss/variables";

$box-shadow: 0 4px 8px rgb(38 50 56 / 24%);
$primary-color: #263238;
$primary-color-hover: #0c1317;
$white: #fff;
$primary-color-disabled: rgb(38 50 56 / 12%);

.Button {
  .btn {
    border-radius: 5px;
    padding: 9px 16px;
    font-family: $recon-font-primary;
    font-style: $recon-font-style;
    font-weight: bold;
    font-size: $recon-font-size;
    line-height: 18px;
    align-items: center;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: 0;
  }

  .btn:focus {
    box-shadow: none !important;
  }

  .btn-primary:hover {
    background-color: $primary-color-hover;
  }

  .btn-primary {
    box-shadow: $box-shadow;
    background-color: $primary-color;
    color: $white;

    &:focus,
    &.focus,
    &:active,
    &:active:focus,
    &.active {
      box-shadow: $box-shadow !important;
      background-color: $primary-color !important;
      color: $white !important;
    }
  }

  .btn-secondary {
    border: 0;
    background-color: $white;
    color: $primary-color;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &:active:focus,
    &.active {
      border: 0 !important;
      background-color: $white !important;
      color: $primary-color !important;
    }
  }

  .btn-primary:disabled {
    box-shadow: none;
    background-color: $primary-color-disabled;
    color: $primary-color;
    cursor: not-allowed;
  }

  .text-secondary {
    color: $white !important;
  }

  .text-primary {
    color: $primary-color !important;
  }

  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
    border-width: 2px;
  }

  .spinner-padding-adjustment {
    padding-top: 13px;
    padding-bottom: 13px;
  }
}
