@import "scss/variables";

.TagComponent-tag {
  display: flex;
  margin-right: $spacing-md;
  font-weight: $recon-medium;
  font-size: 12px;
  border-radius: 6px;
  height: 100%;
  white-space: nowrap;
  align-items: center;
  justify-content: flex-start;

  .TagComponent-tag-padding {
    padding: 0 $spacing-sm;
    display: flex;

    .TagComponent-tag-icon {
      align-self: center;
      font-size: 15px;
    }

    .TagComponent-tag-name {
      padding: 0 3px;
    }

    .TagComponent-tag-remove-button {
      align-self: center;

      .TagComponent-tag-remove-icon {
        color: rgb(38 50 56 / 38%);
        font-size: 12px;
      }
    }
  }
}

.TagList-truncate-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
  margin-right: $spacing-md;
}
