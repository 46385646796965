@import "../../../scss/variables";
@import "../../../scss/theme/variables";

.LabelValue {
  &-container {
    display: flex;

    &-row {
      flex-direction: row;
      align-items: center;
    }

    &-column {
      flex-direction: column;
      justify-content: center;
    }
  }
}
