@import "../../../scss/variables";

.VehicleImageView {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.VehicleImageView-size-normal {
  height: 120px;
  width: 160px;
  margin: 0 auto;

  &.no-image {
    background-color: unset !important;
    border: unset !important;

    svg {
      width: 116px;
    }
  }
}

.VehicleImageView-size-sm {
  height: 90px;
  width: 120px;
}

.VehicleImageView-size-xs {
  height: 75px;
  width: 100px;
}

.VehicleImageView-size-square {
  height: 120px;
  width: 120px;
  background-size: contain;
}
