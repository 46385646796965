@import "./scss/theme/variables";
@import "~bootstrap/scss/bootstrap";
@import "./scss/variables";
@import "./scss/theme/theme";

* {
  box-sizing: border-box;
}

body,
html {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $recon-page-background !important;
}

body,
th,
td,
input,
select,
button {
  font-size: 14px;
  font-family: "Source Sans Pro", "Open Sans", sans-serif !important;
  color: #333;
}

a,
.link {
  cursor: pointer;
  color: $brand-link-color;

  &:hover {
    color: $brand-link-color;
  }
}

.pane-container {
  border-radius: 4px;
  overflow: hidden;
}

.pane-content {
  padding: 0 $spacing-lg;
}

.desktop-viewport {
  .item-block {
    background-color: $recon-item-background-color;
    padding-left: $spacing-lg;
    transition: background-color 0.5s;

    &.selected {
      background-color: $recon-selected-item-background-color;
      border-left: $selected-border-width solid $recon-primary;
      padding-left: $spacing-lg - $selected-border-width;
    }

    &:hover {
      background-color: $recon-selected-item-background-color;
    }
  }
}

.grey-background {
  background: $recon-page-background !important;
}

.white-background {
  background: $recon-white !important;
}

.dropdown-toggle.btn {
  font-size: 15px;
}

.dropdown-item.active,
.dropdown-item:hover {
  color: rgb(0 0 0 / 87%);
  background: $recon-background-tertiary;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page {
  position: absolute;
  inset: 0;
}

.relative {
  position: relative;
}

.root {
  height: 100vh;
}

.flex-rows {
  display: flex !important;
  flex-direction: column;
}

.flex-columns {
  display: flex !important;
  flex-direction: row;
}

.flex-grow {
  flex: 1 1 0 !important;
}

.flex-grow-2 {
  flex: 2 2 0 !important;
}

.flex-grow-3 {
  flex: 3 3 0 !important;
}

.flex-grow,
.flex-grow-2,
.flex-grow-3 {
  flex-wrap: nowrap;
  min-width: 0;

  &.vertical-scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center !important;
}

.center-content {
  height: 90%;

  @extend .flex-rows;
  @extend .justify-center;
  @extend .align-center;
}

.vertical-scroll {
  overflow-y: auto !important;
}

button:focus {
  outline: none;
}

button,
.button {
  border: 1px solid #444;
  padding: 1px 5px;
  color: #444;
  font-weight: bold;
  line-height: 22px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;

  &.primary {
    background: #444;
    color: #fff;
  }

  &.positive {
    background: #0c0;
    border-color: #0b0;
    color: #fff;
  }
}

.bold {
  font-weight: bold !important;
}

.grey-text {
  font-family: OpenSans-Light, "Open Sans Light", "Open Sans", sans-serif;
  color: $recon-grey;
}

.blue-text {
  font-family: OpenSans, "Open Sans", sans-serif;
  color: $recon-secondary;
}

.blue-button {
  background-color: $recon-secondary !important;
  color: $recon-white !important;
  font-weight: bold !important;
  line-height: 2.75 !important;
}

.white-button {
  background-color: $recon-white !important;
  color: $recon-secondary !important;
  font-weight: bold !important;
  line-height: 2.75 !important;
}

.round-button {
  border-radius: 6px !important;
}

/* Margin */
.margin-bottom {
  margin-bottom: $base-margin !important;
}

.margin-left {
  margin-left: $base-margin !important;
}

.margin-right {
  margin-right: $base-margin !important;
}

.margin-top {
  margin-top: $base-margin !important;
}

.double-margin-bottom {
  margin-bottom: $base-margin * 2 !important;
}

/* Padding */

.padding-left {
  padding-left: $base-margin !important;
}

.padding-right {
  padding-right: $base-margin !important;
}

.padding-top {
  padding-top: $base-margin !important;
}

.padding-bottom {
  padding-bottom: $base-margin !important;
}

.padding {
  padding: 2 * $base-margin;
}

.no-padding {
  padding: 0 !important;
}

.small-text {
  font-size: 8px;
}

.input {
  input[type="text"] {
    background-color: $recon-white !important;
  }

  input[type="password"] {
    background-color: $recon-white !important;
  }
}

.input-error {
  input[type="text"] {
    background: $recon-error-light !important;
  }

  input[type="password"] {
    background: $recon-error-light !important;
  }
}

/* Default Classes */

.full-height {
  height: 100%;
}

.full-width {
  width: 100% !important;
}

.full-height-calc {
  height: calc(100%) !important;
}

.half-height {
  height: 50% !important;
}

.half-width {
  width: 50% !important;
}

.font-light {
  font-weight: $recon-light !important;
}

.font-bold {
  font-weight: $recon-bold !important;
}

.font-heavy {
  font-weight: $recon-heavy !important;
}

.icon-large {
  width: 4rem;
  height: 4rem;
}

.ok-status-text {
  color: $recon-ok !important;
}

.danger-status-text {
  color: $recon-danger !important;
}

.warning-status-text {
  color: $recon-warning !important;
}

.divider {
  height: 0;
  width: 100%;
  border-bottom: 1px solid $recon-divider-color;
}

.no-image {
  justify-content: center;
  color: $recon-tertiary;
  background-color: $recon-grey-summary !important;
  align-items: center;
  display: flex;

  svg {
    width: 55px;
    height: auto;
  }
}

.no-margin {
  margin: 0 !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inline-block {
  display: inline-block;
}

.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error {
  background-color: $recon-error !important;
  color: $recon-white !important;
}

.hide {
  visibility: hidden !important;
}

.warning {
  background-color: $recon-warning !important;
  color: white;
}

.danger {
  background-color: $recon-danger !important;
  color: white;
}

.info {
  background-color: $recon-background-tertiary !important;
  color: $recon-white;
}

.ok {
  background-color: $recon-ok !important;
  color: $recon-white;
}

.word-wrap {
  word-wrap: break-word !important;
}

.clear {
  color: transparent !important;
  background-color: transparent !important;
}

.wrap {
  flex-wrap: wrap !important;
}

.no-wrap {
  flex-wrap: nowrap !important;
}

.disable-click {
  pointer-events: none;
}

.text-ellipsis {
  text-overflow: ellipsis !important;
}

.blue-outline {
  border-width: 0.5px;
  border-style: solid;
  border-color: $recon-secondary;
}

.modal {
  z-index: 1650 !important;
}

.modal-backdrop {
  z-index: 1600 !important;
}

.modal-dialog-container {
  z-index: 1350 !important;
}

.modal-header-container {
  border-bottom: none !important;
}

.modal-sub-title {
  color: $recon-grey !important;
  margin-bottom: $spacing-lg !important;
}

.modal-footer-container {
  border-top: none !important;
  background-color: $recon-background-tertiary !important;
}

.modal-footer-button-base {
  width: 115px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: $recon-secondary !important;
  font-weight: bold !important;
  background-color: $recon-background-tertiary !important;
  border: none !important;
  border-bottom: 4px solid $recon-secondary !important;
}

.nav-tabs .nav-link {
  color: $recon-secondary;
  border-radius: 0 !important;
}

.tab-container {
  margin: 0 !important;
  border-bottom: 1px solid $recon-background-tertiary !important;
  padding: 0 $spacing-lg !important;
}

.evenly-spaced-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  margin: 0;
  padding: 0;
}

.evenly-spaced-item {
  flex: 1;
  text-align: center;
}

.line-through {
  text-decoration: line-through;
}

.display-none {
  display: none;
}

.horizontal-scroll {
  overflow-x: scroll !important;
}

.double-margin-top {
  margin-top: $spacing-xl;
}

.pointer {
  cursor: pointer !important;
}

.no-pointer {
  cursor: default !important;
}

.optional-label {
  text-align: right;
  font-size: 10px;
}

h4 {
  margin: 14px 0 0;
  font-size: 14px;
  font-weight: $recon-medium;
  line-height: 1.3;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: rgb(38 50 56 / 60%);
}

label.heading {
  margin: 8px 0 2px;
  font-size: 14px;
  font-weight: $recon-medium;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: rgb(38 50 56 / 60%);
}

.key-value {
  margin: 0;

  dt,
  th {
    margin: 0;
    padding: 2px 8px 0 0;
    font-size: 14px;
    font-weight: $recon-medium;
    letter-spacing: 0.4px;
    line-height: 1.3;
    text-transform: uppercase;
    color: rgb(38 50 56 / 60%);
    white-space: nowrap;
  }

  dd,
  td {
    margin: 0;
    font-size: 16px;
    letter-spacing: 0.3px;
    white-space: nowrap;
  }
}

.disable-opacity {
  opacity: 0.3 !important;
}

/* Dropdown */
.dropdown-menu {
  max-height: 400px;
  font-size: 14px;
  overflow-y: auto;
  box-shadow: 0 4px 10px rgb(0 0 0 / 20%);
}

.MuiSnackbar-root.MuiSnackbar-anchorOriginTopCenter {
  z-index: 6000;
}

.overflow-hidden {
  overflow: hidden;
}

@media only screen and (device-width >= 768px) and (device-width <= 1024px) {
  .MuiInputLabel-outlined {
    transform: translate(14px, 8px) scale(1) !important;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
  }
}

.MuiFormControl-root label {
  background-color: white;
  padding: 3px;
}
