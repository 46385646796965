@import "../../../scss/variables";

.ShopperModal {
  .TextInput {
    border-color: hsl(0deg 0% 80%) !important;
    width: 100%;
    margin: 0 18px 18px 0;
    color: #263238;
    height: 56px;

    @media screen and (width <= 768px) {
      margin: 0 16px 24px 0;
    }
  }

  h6 {
    margin-bottom: 12px;
  }

  &-body {
    overflow-y: auto;
  }

  &-form {
    &-column {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &-row {
      display: flex;
      flex-flow: row nowrap;
      width: auto;
      align-items: baseline;
      align-content: flex-start;

      @media screen and (width <= 768px) {
        flex-wrap: wrap;
      }

      &-select {
        min-width: 149px;

        @media screen and (width <= 768px) {
          margin: 0 16px 24px 0;
          padding-right: 0;
          width: 100%;
        }

        [class*="-control"] {
          margin: 0 16px 0 0;
          height: 56px;

          @media screen and (width <= 768px) {
            margin: 0;
          }
        }
      }
    }

    &-input {
      &-city {
        width: 362px;
      }

      &-zip {
        width: 149px;
      }
    }
  }

  .ShopperDialog {
    max-width: 874px;
    margin: 0 auto;
    width: 100%;

    @media screen and (width <= 874px) {
      width: 95vw;
      min-width: 95vw;
      margin: auto;
      min-height: 100vh;
    }

    .modal-header,
    .modal-body,
    .modal-footer {
      margin: 0;
      padding: 14px 24px;
    }

    .modal-header {
      padding: 16px 24px;
    }

    .modal-body {
      padding: 14px 6px 0 24px;
    }

    .modal-footer {
      border-top: 0;
      margin: 0 4px 16px 16px;

      @media screen and (width <= 768px) {
        margin: 0 0 16px;
      }
    }

    .modal-title {
      font-weight: 600;
    }
  }

  .ShopperModal-body {
    padding: 0 8px 16px 24px;

    .version-info {
      font-size: 10px;
      color: darkgrey;
    }
  }

  .ShopperModal-cancel-button {
    border: none;
    margin-right: 20px;
    color: $recon-background-secondary;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    background-color: #fff;
  }

  .ShopperModal-submit-button {
    width: 83px;
    border-radius: 3px;
    background: $recon-background-secondary;
    box-shadow: 0 4px 8px rgb(38 50 56 / 24%);
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    margin: 2px;

    &:disabled {
      background-color: rgb(38 50 56 / 12%) !important;
      border-color: rgb(38 50 56 / 12%) !important;
      color: $recon-background-secondary !important;

      > div {
        opacity: 0.3;
      }
    }
  }

  .ShopperModal-shopper-row {
    background-color: blue;

    .Button {
      padding-top: 16px;
    }
  }
}
