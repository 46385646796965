.FlatHistory {
  padding: 0 !important;

  &-content {
    max-height: calc(100% - 48px) !important;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 0;
    padding-right: 0 !important;
    padding-left: 16px !important;

    .no-note-message {
      padding-left: 24px !important;
    }
  }
}
