@import "scss/variables";
@import "scss/theme/variables";

.vendor-no-vehicles {
  align-items: center;
  margin-top: 200px;
  font-size: 24px;
  text-align: center;
}

.vendor-permission-container {
  align-items: center;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;

  .vendor-permission {
    max-width: 650px;
    text-align: center;
    font-size: 24px;
  }
}

.vendor-inventory-list {
  display: flex;
  flex-direction: column;
  background-color: #eaecec;
  flex: 1;

  .vendor-inventory-list-content {
    display: flex;
    flex: 1;
  }

  .vendor-inventory-list-container {
    display: flex;
    flex: 1;
    position: relative;
    flex-direction: column;
  }

  .vendor-inventory-list-footer {
    padding: 16px;
    background-color: $recon-background-tertiary;
  }
}
