@import "scss/variables";
@import "scss/theme/variables";

.vendor-header {
  margin: 10px;
  display: flex;
  flex: 1;

  @media screen and (width <= 600px) {
    .VehicleSearch-container {
      flex: 1;
      order: 0;
      margin-right: 10px;
    }
  }
}
