@import "../../../scss/variables";

.ReportContainer {
  width: 100%;
  padding-bottom: $spacing-slg;

  .ReportContainer-panel-container {
    .ReportContainer-overview {
      display: flex;
      flex-flow: row wrap;
      align-self: flex-start;
    }
  }
}

.ReportContainer-loadingContainer {
  padding: 150px 0;
}
