@import "../../../../scss/variables";
@import "../../../../scss/mixins";

.RooftopSelect {
  .Dropdown .Dropdown-btn.dropdown .dropdown-toggle.btn {
    @include md {
      padding-right: 0 !important;
    }

    @include sm {
      padding-right: 0 !important;
    }

    @include xs {
      padding-right: 0 !important;
    }
  }

  .DropdownButton-selectedOption {
    color: $recon-white;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    display: flex;
    flex-wrap: nowrap;

    @include md {
      margin-right: 0;
    }

    @include sm {
      margin-right: 0;
    }

    @include xs {
      margin-right: 0;
    }
  }

  &-span {
    color: $recon-white;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.3px;
    padding-right: 20px;
  }

  .Dropdown .dropdown-menu {
    max-height: 350px !important;
    margin-top: 15px !important;
  }

  .Dropdown {
    .selectShowroom {
      .dropdown-menu {
        left: -212px;
      }
    }
  }
}
