@import "scss/variables";
@import "scss/theme/variables";

.invoices {
  .invoices-main {
    padding: 0;

    .invoices-table-head {
      th {
        font-size: 14px;
        color: $recon-background-secondary;
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    .button {
      color: $recon-background-secondary;
      font-weight: 600;
    }

    .invoices-status-paid {
      color: #a0d66a;
    }

    .invoices-status-invoiced {
      color: #44c7ff;
    }

    .invoices-list {
      flex: 1;
      padding: 8px 16px;

      .invoices-list-col {
        display: flex;
        flex-direction: column;
      }

      .invoices-list-row {
        display: flex;
        flex-direction: row;
        gap: 0 16px;
      }

      .invoices-list-name {
        flex: 1;
        font-size: 18px;
      }

      .invoices-list-details {
        font-size: 16px;
        color: #0000008a;
      }

      .invoices-list-total {
        font-size: 18px;
      }

      .invoices-status-paid,
      .invoices-status-invoiced {
        margin-left: 8px;
      }
    }
  }
}
