@import "scss/variables";
@import "scss/theme/variables";

.VehicleCardDesktop {
  display: flex;

  .VehicleCardDesktop-details {
    min-width: 300px;
    max-width: 300px;
    min-height: 250px;

    .VehicleCardDesktop-vehicleInfo {
      display: flex;
      align-items: center;
      justify-content: center;

      .VehicleCard-year-make-model {
        margin-left: $spacing-md;
        flex-grow: 1;
      }
    }

    .VehicleCard-details-table {
      margin: $spacing-sm 0;
    }

    .TagListSrp {
      height: 40px;
      flex-wrap: wrap;

      li {
        padding: 1px 0;
      }
    }

    .VehicleCardProgressBar {
      padding-top: $spacing-sm;
      height: 34px;
    }
  }

  .border-right {
    margin: -15px $spacing-md;
  }

  .VehicleCard-recon-inventory-tabs {
    flex: 1;
    min-width: 210px;
    min-height: 260px;

    .VehicleCard-tab-container {
      .LabelValue-value {
        .DropdownButton-selectedOption {
          min-width: 97px;
        }
      }
    }
  }

  .VehicleCard-recon-inventory-tabs.VehicleCard-recon-inventory-tabs-no-notes {
    max-width: unset;
    min-width: unset;
  }

  .VehicleCardNotes {
    flex: 1.75;

    .VehicleCardNotes-notes {
      max-height: 210px;
    }
  }

  .VehicleCardNotes.VehicleCardNotesTab {
    .VehicleCardNotes-notes {
      max-height: 180px;
    }
  }
}
