@import "../../../../scss/theme/variables";
@import "../../../../scss/variables";

.item-eng-contact-link {
  color: $primary;
}

.item-eng-non-anchor-link:hover {
  color: $brand-link-color;
  text-decoration: underline;
}

div.engage-table-custom {
  cursor: default;
  overflow-x: auto;
  padding: 0;

  .cell {
    @extend .engage-cell;
  }

  border-bottom: 1px solid rgb(222 226 230);
}

.column-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #263238;
  text-transform: uppercase;
}

.engage-cell {
  padding: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.2rem;

  &.centered {
    text-align: center;
  }

  * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

div.engage-header {
  background-color: #fafafafa;
  text-transform: uppercase;

  @extend .column-label;
}

div.engage-row {
  cursor: pointer;
  display: grid;
  padding: 0 1rem;
  grid-template-columns:
    minmax(24px, 30px)
    minmax(100px, 150px)
    minmax(180px, 1.5fr)
    minmax(100px, 1.25fr)
    minmax(80px, 1fr)
    repeat(4, minmax(40px, 130px))
    135px;
  align-items: center;

  &:not(:first-child) {
    border-top: 1px solid rgb(222 226 230);
  }

  &:hover {
    background-color: rgb(222 226 230 / 10%);
  }

  &.current-row {
    background-color: #fafafafa;

    &:hover {
      background-color: #f3f3f3;
    }
  }

  &.sub-row {
    cursor: default;
    grid-template-columns: repeat(3, 1fr);
  }

  &.helper {
    grid-template-columns: 1fr;
    padding: 4rem 2rem;
    min-height: 100px;
    text-align: center;
    background-color: #fff;
  }
}
