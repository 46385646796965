@import "../../../../scss/variables";

.ActiveReconSummary-item-row:hover {
  &:hover {
    background-color: $recon-hover;
    cursor: pointer;
  }
}

.ActiveReconSummary-container {
  min-width: 600px;
  overflow: hidden;
}

.ActiveReconSummary-horizontal-scroll-container {
  overflow: hidden;
  width: inherit;
  overflow-x: auto;
}

.ActiveReconSummary-title-row {
  height: 44px;
}

.ActiveReconSummary-item-row {
  height: 54px;
  border-style: solid;
  border-width: 1px 0 0;
  border-color: rgb(128 128 128 / 24.7%);
  border-radius: 1px;
}

.ActiveReconSummary-title-text {
  line-height: 44px;
  text-transform: uppercase;
}

.item-centered-vertically {
  line-height: 54px;
}

.ActiveReconSummary-parent-name-container {
  margin-left: 30px;
}

.ActiveReconSummary-child-name-container {
  margin-left: 52px;
  margin-right: -22px;
}

.overage-bad {
  color: $recon-danger;
}

.overage-neutral {
  color: $recon-secondary;
}

.overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
