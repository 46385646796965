@import "scss/variables";

$tag-list-min-height: 25px;

.TagListSrp {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .TagList-truncate-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 18px;
    white-space: nowrap;

    .TagListSrp-icon-button {
      height: $spacing-lg;
      width: $spacing-lg;
      border: none;
      background-color: unset;
      position: relative;
      padding: 0;
      margin-left: $spacing-sm;
      display: flex;
      justify-content: center;
      align-items: center;

      .TagListSrp-icon {
        height: $spacing-lg;
        width: $spacing-lg;
        border: none;
      }
    }
  }
}

.TagListSrp-empty-container {
  background-color: unset;
  border: none;
  height: 40px;
  max-width: 85px;
  text-align: left;
}

.TagListSrp-empty-container-no-button {
  height: 16px;
}
