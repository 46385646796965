.carfax-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 2%;
  padding-right: 2%;
}

.carfax-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}
