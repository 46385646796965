.Pane {
  height: 100%;
  margin: 0;

  .pane-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .pane-body {
    flex: 1;
    overflow: auto;
  }
}
