.mobile-viewport .VehicleList2 {
  padding: 0 14px;
}

.VehicleList2 {
  padding: 0 11px;

  @media screen and (width >= 768px) and (width <= 991px) {
    .row-margins {
      > :first-child {
        margin-right: -9px;
      }

      > :last-child {
        margin-left: 3px;
      }
    }
  }

  .VehicleList-list-container > div {
    width: 100% !important;
  }

  .end-message {
    font-size: small;
    color: #bebebe;
  }

  .VehicleList-row {
    margin: 0;
  }
}

.VehicleList-scroll-content {
  padding: 0 14px;
}

.mobile-viewport .VehicleList {
  padding: 0;
}

.VehicleList2-single {
  padding-left: 25px !important;
}
