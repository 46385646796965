@import "scss/variables";
@import "scss/theme/variables";

.invoice {
  background-color: #cfcfcf;
  padding: 64px 0;
  width: 100%;
  height: auto;

  .invoice-page {
    width: 800px;
    min-height: 1100px;
    margin: 0 auto;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 24px 32px;
    background-color: $light;

    .horizontal-line::after {
      content: " ";
      display: block;
      border-top: 2px solid $recon-grey;
      border-bottom: 1px solid $recon-grey;
      border-radius: 8px;
    }

    .horizontal-line-margin-top::after {
      margin-top: 8px;
    }

    .horizontal-line-margin-bottom::after {
      margin-bottom: 4px;
    }

    .invoice-header {
      .header-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .header-left {
          .vendor-logo-container {
            padding-bottom: 8px;

            .vendor-logo {
              height: 90px;
              object-fit: contain;
            }
          }
        }

        .header-middle {
          display: flex;
          justify-content: center;
          align-items: center;

          .invoice-status {
            font-size: 44px;
            font-weight: bold;
            transform: rotate(-15deg);
            border-radius: 10px;
            padding: 0 10px;

            &.paid {
              color: $danger;
              border: 4px solid $danger;
              min-width: 150px;
              text-align: center;
            }

            &.invoiced {
              color: $info;
              border: 4px solid $info;
            }
          }
        }

        .header-right {
          .vendor-details {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .vendor-name {
              font-size: 18px;
              font-weight: 600;
            }

            .vendor-detail {
              font-size: 16px;
            }
          }
        }

        .invoice-number-label {
          font-size: 16px;
        }

        .invoice-number {
          font-weight: bold;
          font-size: 16px;
        }
      }

      .header-bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .header-left {
          .invoice-details-container {
            display: flex;
            flex-direction: column;

            .invoice-detail {
              font-size: 16px;
            }

            .invoice-detail-bold {
              font-size: 16px;
              font-weight: 600;
            }
          }
        }

        .header-right {
          .tenant-details-container {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .tenant-detail {
              font-size: 16px;
            }

            .tenant-detail-bold {
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
    }

    .invoice-main {
      flex: 1;

      .item {
        padding: 8px 0;
        padding-bottom: 4px;

        .vehicle {
          background-color: $recon-dark-grey;
          padding: 5px 14px;
          display: flex;
          flex: 1;
          flex-direction: row;
          border-radius: 4px;

          .year-make-model {
            flex: 1;
            color: $light;
          }

          .vin {
            flex: 1;
            color: $light;
          }

          .stock {
            flex: 1;
            color: $light;
          }
        }

        .task {
          display: flex;
          flex-direction: column;
          padding-top: 8px;
          padding-bottom: 4px;

          .task-row {
            display: flex;
            flex-direction: row;

            .task-item {
              display: flex;
              flex: 1;
              font-size: 12px;
              color: $recon-grey;
              padding-bottom: 2px;
              padding-left: 4px;
            }

            .task-item-left {
              margin-left: 8px;
            }

            .task-item-right {
              margin-right: 8px;
            }

            .task-item-border {
              border-bottom: 2px solid $recon-grey;
            }

            .task-value {
              display: flex;
              flex: 1;
              font-size: 16px;
              color: $recon-black;
              padding-left: 4px;
              margin-top: 4px;
            }

            .task-value-left {
              margin-left: 8px;
            }

            .task-value-right {
              margin-right: 8px;
            }

            .task-value-background {
              background-color: $recon-hover;
            }

            .task-total {
              justify-content: flex-end;
              padding-right: 4px;
            }
          }
        }
      }
    }

    .invoice-footer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .total-price {
        padding-right: 4px;
        font-size: 18px;
      }
    }
  }
}

@media only screen and (max-width: $screen-md-min) {
  .invoice {
    padding: 0;
    margin-top: 2px;

    .invoice-page {
      padding: 24px 16px;
      width: auto;
    }
  }
}

@media only screen and (max-width: $screen-sm-min) {
  .invoice {
    .invoice-page {
      padding: 16px;
    }
  }
}
