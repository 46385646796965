@import "../../../../scss/variables";

.VehicleStepReportModalV3 {
  .modal-dialog {
    max-width: 90%;
  }

  .modal-content {
    border: none;
    border-radius: 0;
  }

  .modal-header {
    border: none;
    padding: $spacing-lg;
  }

  .modal-body {
    padding: $spacing-md $spacing-lg $spacing-lg;
    overflow-x: auto;
  }

  .modal-footer {
    border: none;
    padding: $spacing-md $spacing-lg $spacing-lg;
  }

  .modal-title {
    padding-top: $spacing-md;
    font-size: 24px;
    font-weight: $recon-medium;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  .VehicleStepReportModal-content {
    min-width: 880px;
  }

  .VehicleStepReportModal-titleRow {
    display: flex;
    width: 100%;
    align-items: stretch;
    border-radius: 5px;
    overflow: hidden;
    color: $recon-white;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-bottom: $spacing-lg;
    min-height: 50px;
    box-shadow: 0 4px 15px rgb(0 0 0 / 25%);

    .VehicleStepReportModal-vehicleInfoTitle,
    .VehicleStepReportModal-reconditionEffortTitle,
    .VehicleStepReportModal-reconditionCostsTitle {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .VehicleStepReportModal-vehicleInfoTitle {
      flex: 1 0 20%;
      background-color: #494949;
    }

    .VehicleStepReportModal-reconditionEffortTitle {
      flex: 2 0 50%;
      background-color: #d88e00;
    }

    .VehicleStepReportModal-reconditionCostsTitle {
      flex: 1 0 30%;
      background-color: #0084ce;
    }
  }

  .VehicleStepReportModal-table {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgb(0 0 0 / 25%);

    .VehicleStepReportModal-tableHead,
    .VehicleStepReportModal-tableDataRow {
      display: flex;
      width: 100%;
      font-size: 14px;
      letter-spacing: 0.25px;
    }

    .VehicleStepReportModal-tableHead {
      min-height: 30px;
      color: $recon-white;
      font-weight: $recon-medium;
      line-height: 20px;

      .VehicleStepReportModal-tableHead-vehicleInfo {
        background-color: rgb(73 73 73 / 70%);
      }

      .VehicleStepReportModal-tableHead-reconditionEffort {
        background-color: rgb(216 142 0 / 75%);
      }

      .VehicleStepReportModal-tableHead-reconditionCost {
        background-color: rgb(0 132 206 / 70%);
      }
    }

    .VehicleStepReportModal-tableDataRow {
      min-height: 39px;
      line-height: 17.2px;

      &:nth-child(odd) {
        .VehicleStepReportModal-tableDataRow-vehicleInfo {
          background-color: rgb(73 73 73 / 15%);
        }

        .VehicleStepReportModal-tableDataRow-reconditionEffort {
          background-color: rgb(216 142 0 / 15%);
        }

        .VehicleStepReportModal-tableDataRow-reconditionCost {
          background-color: rgb(0 132 206 / 15%);
        }
      }

      &:nth-child(even) {
        .VehicleStepReportModal-tableDataRow-vehicleInfo {
          background-color: rgb(73 73 73 / 30%);
        }

        .VehicleStepReportModal-tableDataRow-reconditionEffort {
          background-color: rgb(216 142 0 / 30%);
        }

        .VehicleStepReportModal-tableDataRow-reconditionCost {
          background-color: rgb(0 132 206 / 30%);
        }
      }
    }

    .VehicleStepReportModal-tableHead-vehicleInfo,
    .VehicleStepReportModal-tableHead-reconditionEffort,
    .VehicleStepReportModal-tableHead-reconditionCost,
    .VehicleStepReportModal-tableDataRow-vehicleInfo,
    .VehicleStepReportModal-tableDataRow-reconditionEffort,
    .VehicleStepReportModal-tableDataRow-reconditionCost {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $spacing-lg;
      padding: $spacing-sm $spacing-lg;
      text-align: center;

      > * {
        flex: 1 0 0;
      }
    }

    .VehicleStepReportModal-tableHead-vehicleInfo,
    .VehicleStepReportModal-tableDataRow-vehicleInfo {
      flex: 1 0 20%;
    }

    .VehicleStepReportModal-tableHead-reconditionEffort,
    .VehicleStepReportModal-tableDataRow-reconditionEffort {
      flex: 2 0 50%;
    }

    .VehicleStepReportModal-tableHead-reconditionCost,
    .VehicleStepReportModal-tableDataRow-reconditionCost {
      flex: 1 0 30%;
    }

    .VehicleStepReportModal-tableDataRow-vehicleInfo {
      line-height: 20px;
    }

    .VehicleStepReportModal-tableDataRow-stockNumber {
      text-decoration: underline;
      cursor: pointer;
    }

    .VehicleStepReportModal-itemOverage {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $spacing-md;

      .VehicleStepReportModal-ArrowContainer {
        margin-left: $spacing-sm;
        filter: drop-shadow(0 0 2px #fff);
        width: 16px;

        &.ArrowUp {
          svg path {
            fill: #d50000;
          }
        }

        &.ArrowDown {
          transform: rotate(180deg);

          svg path {
            fill: #00ca39;
          }
        }
      }
    }
  }
}
