@import "../../../../scss/variables";
@import "../../../../scss/theme/variables";

.FilterColumn {
  height: 100%;
  background-color: $recon-white;
  display: flex;
  flex-direction: column;

  &-active-filters {
    padding-left: 24px;
    padding-right: 24px;

    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-clear-button {
      padding: 3px 8px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $primary;
      border: 1px solid rgb(38 50 56 / 60%);
      box-sizing: border-box;
      border-radius: 5px;
      background-color: $light;
      margin-top: 14px;
      margin-bottom: 14px;
    }

    &-chips {
      display: flex;
      flex-flow: row wrap;
    }
  }

  &-container {
    flex: 1;
    overflow-y: auto;
    border-bottom: #eee 1px solid;
    border-right: #eee 1px solid;
    padding-bottom: 60px;
  }
}
