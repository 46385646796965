@import "../../../../scss/variables";

.Overview {
  height: 100%;
  background-color: $recon-white;

  &-container {
    border-bottom: 1px solid $recon-alt-text-12;
  }

  .step-items {
    margin-top: 24px;
    padding-bottom: 60px;

    @media screen and (width <= 1165px) {
      margin-bottom: 60px;
    }
  }

  .totals {
    .col {
      text-align: center;

      .selection-area {
        padding: 8px 4px;
        margin: 0 4px;

        &:hover {
          background-color: $recon-hover;
          cursor: pointer;
        }

        &.selected-header {
          border: 1px solid #c00;
          background-color: $recon-hover;
          z-index: 200;
          margin-top: -1px;
        }
      }

      .summary {
        font-size: 24px;
        font-weight: 400;
      }

      .summary-ok {
        color: $recon-ok;
      }

      .summary-warning {
        color: $recon-warning;
      }

      .summary-danger {
        color: $recon-danger;
      }

      .summary-total {
        color: $recon-grey;
      }
    }
  }

  .scroll-area {
    height: calc(100vh - 227px);
    overflow-y: auto;
    border-bottom: #eee 1px solid;
    border-right: #eee 1px solid;
    width: 100%;
  }
}
