@import "../../../../scss/variables";

$pinned-component-width: 400px;

.PinnedComponent {
  max-width: 33%;
  min-width: 400px;
  width: $pinned-component-width;
  border-left: 1px solid $recon-alt-text-12;

  &-Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-dropdown {
      .DropdownButton-selectedOption {
        font-weight: 600;
        letter-spacing: 0.3px;
        margin-right: 7px;
      }
    }

    &-close-button {
      border: none;
      background-color: transparent;
      padding: 0 !important;

      &-icon {
        color: $recon-alt-text;
        font-size: 20px !important;
      }
    }
  }

  &-Content {
    padding: 0 !important;
    height: calc(100% - 48px) !important;
    overflow: auto;

    // Notes
    .Notes {
      .PaneHeader {
        display: none;
      }
    }

    // Recalls
    .Recalls {
      .PaneHeader {
        display: none !important;
      }
    }

    // MPI
    .MpiPane-Header {
      display: none !important;
    }
  }
}
