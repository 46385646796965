@import "../../../../scss/variables";
@import "../../../../scss/theme/variables";

.VehicleDetailsModal {
  padding-left: 16px !important;
  padding-right: 16px !important;

  .modal-body {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  &-vehicle-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    padding-right: 12px;
    padding-bottom: 12px;

    &-vehicle {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &-info-image {
    width: 56px !important;
    height: 44px !important;
    font-size: 44px;
    color: rgb(158 158 158);
    margin-right: 16px;
  }

  &-year-model {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: $primary;
    display: flex;
    flex-direction: column;
  }

  &-bold-text {
    font-weight: 600;
  }

  &-more-icon {
    fill: $muted !important;
  }

  &-info-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 16px;

    > div {
      flex: 1;
    }
  }

  &-label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $primary;
    opacity: 0.7;
  }

  &-value {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $primary;

    .TextCopy {
      justify-content: unset;
      align-items: unset;
    }
  }

  &-dropdown-container {
    position: relative;
  }

  .dropdown-menu.show {
    opacity: 1 !important;
    pointer-events: auto !important;
    top: 0 !important;
    right: 45% !important;
    left: auto !important;
    margin-top: 0 !important;
    margin-right: 0.125rem !important;
    max-height: 200px !important;
  }

  .loadingIconAlongside {
    display: flex;
  }

  .Dropdown .Dropdown-btn.dropdown .dropdown-toggle.btn {
    font-size: $recon-font-size !important;
    background-size: 8px 6px;
    background-position: right 8px;
  }
}
