.PortalAlert {
  position: absolute;
  top: 60px;
  right: 0;
  left: 0;
  z-index: 5500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
