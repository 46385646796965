@import "scss/variables";
@import "scss/theme/variables";

.NoteAttachments-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: $spacing-sm;

  .NoteAttachments-attachment {
    display: flex;
    position: relative;
    margin-right: $spacing-md;
    margin-bottom: $spacing-md;
    height: 100px;
    width: 100px;

    .VehicleImageView {
      background-size: cover;
      width: 100%;
      height: 100%;
      border: 2px solid $recon-background-tertiary;
      border-radius: 8px;
    }

    .dropleft {
      border: 1px solid $recon-background-tertiary;
      border-radius: 8px;
      background: $recon-background-secondary;
      color: $recon-background-tertiary;
      position: absolute;
      right: -5px;
      top: -5px;
    }
  }
}
