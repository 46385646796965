@import "../../../../scss/variables";
@import "../../../../scss/mixins";

.icon-upload {
  display: flex;
  color: $recon-grey !important;
  margin-right: $spacing-lg;
  height: 50px !important;
  width: 50px !important;
  align-items: center;
  justify-content: center;
}

.icon-uploading {
  display: flex;
  background-color: $recon-alt-text-12 !important;
  height: 50px !important;
  width: 50px !important;
  align-items: center;
  justify-content: center;
}

.icon-loading {
  display: flex;
  background-color: $recon-alt-text-12 !important;
  border-radius: 5px;
  margin-right: $spacing-lg;
  height: 50px !important;
  width: 50px !important;
  align-items: center;
  justify-content: center;

  & > div {
    margin-top: 0 !important;

    & > div {
      width: 20px !important;
      height: 20px !important;
      border-width: 2px;
    }
  }
}

.angle-img {
  height: 50px;
  width: 50px;
  margin-right: $spacing-lg;
  object-fit: cover;
}

.Syndication-delete-icon {
  fill: $recon-icon-color !important;
}

.Syndication {
  @include sm {
    max-height: 496px;
  }
}

.Syndication-row:last-child {
  margin-bottom: 50px !important;
}

.Syndication-clickable {
  cursor: pointer;
}
