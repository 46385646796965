@import "scss/variables";

.VehicleNeedsAttentionCol {
  padding: 12px 8px !important;

  .card {
    cursor: pointer;
    border: 0 solid rgb(128 128 128 / 24.7%) !important;
    background-color: white !important;
    border-radius: 5px !important;
    box-shadow: 0 4px 8px rgb(0 0 0 / 8%) !important;
    padding: 15px 24px;
  }

  .vehicle-attention-description-container {
    margin-left: 16px;
    letter-spacing: 0.3px;

    .year-make {
      font-weight: 600;
      font-style: normal;
      font-size: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .model {
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
    }

    padding-bottom: 16px;
  }
}

.VehicleNeedsAttentionCard-car-icon-container {
  width: 80px;
  height: 80px;
}

.VehicleNeedsAttentionCard-car-icon {
  font-size: 80px !important;
  color: $recon-tertiary;
}

.VehicleNeedsAttentionCard-step-name-text {
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 0.25px;
  color: $recon-alt-text;
}

.VehicleNeedsAttentionCard-step-progress-text-col {
  text-align: right;
}

.VehicleNeedsAttentionCard-time-name-text {
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.3px;
  color: $recon-alt-text;
}

.VehicleNeedsAttentionCard-row {
  margin-top: 12px;
  padding: 0;
  white-space: nowrap;
}

.VehicleNeedsAttentionCard-label {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  color: $recon-alt-text;
}

.VehicleNeedsAttentionCard-icons-container {
  .VehicleNeedsAttentionCard-icon {
    height: 20px;
    width: 20px;
    color: $recon-error;
    align-self: center;
  }
}

.VehicleNeedsAttentionCard-icon-hover {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.VehicleNeedsAttentionCard-icon-hover:hover {
  background: rgb(38 50 56 / 12%);
}
