@import "../../../../scss/variables";
@import "../../../../scss/theme/variables";

.FlatTaskInput {
  width: 100%;

  input {
    width: 100%;
    border: none;

    &:focus {
      outline: none;
      border: 1px solid $info;
    }
  }
}
