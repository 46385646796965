@import "../../../../scss/variables";

.RecallItem-card {
  border-radius: initial !important;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: solid 1px $recon-alt-text-12;

  button {
    cursor: pointer;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: none;
    padding: $spacing-slg 0;
    min-height: 96px;
    background-color: $recon-white;
  }
}

.RecallItem-warning-icon {
  margin-left: 10px;
  margin-right: 26px;
  width: 28px;
}

.RecallItem-title-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  font-family: $font-family-sans-serif;
  font-style: normal;
  width: 0;
  align-items: flex-start;
}

.RecallItem-header-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: $recon-alt-text;
}

.RecallItem-header-value {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  font-weight: normal;
  white-space: pre-wrap;
  text-align: left;
  color: $recon-background-secondary;
}

.RecallItem-body {
  margin-left: 64px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;

  p {
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    color: $recon-black;
  }
}

.RecallItem-body-label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: $recon-alt-text;
}

.RecallItem-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0 !important; /* The Row component is setting to -15px */
  margin-right: 0 !important; /* The Row component is setting to -15px */

  div {
    display: flex;
    justify-content: center;
  }

  .icon-col {
    margin-right: 14px;
    padding-left: $spacing-md;
    margin-left: auto;
    color: $recon-alt-text-38;
  }

  .name-col {
    flex: 3 1 250px;
    justify-content: start;
    max-width: 380px;
    overflow: hidden;
  }

  .id-status-col {
    display: flex;
    justify-content: center;
    flex: 3 2 175px;
  }

  .id-col {
    flex: 2 1 100px;
  }

  .status-col {
    flex: 1 1 75px;
  }

  .RecallItem-name-text,
  .RecallItem-status-text {
    text-transform: initial;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
