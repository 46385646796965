@import "scss/variables";
@import "scss/theme/variables";

.TasksTableFilters-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid rgb(38 50 56 / 12%);
  background-color: #fafafa;
  padding: $spacing-sm 0;
  line-height: initial;

  .Dropdown-expand-icon {
    margin-top: unset;
  }

  &-label {
    text-transform: uppercase;
    margin-right: $spacing-sm;
    color: $recon-grey;
    font-size: 16px;
  }
}
