@import "../../../scss/variables";
@import "../../../scss/theme/variables";

$max-width: 180px;
$icon-size: 12px;
$right-padding: $icon-size + 2px;

.Dropdown {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  white-space: nowrap;

  .Dropdown-btn.UsersDropdown {
    width: calc(95%);
    padding-top: 0%;
  }

  &-input {
    width: 100%;
    padding: 10px 0 10px 10px;
    border: 0;

    &:focus {
      outline: none !important;
    }
  }

  .DropdownButton-selectedOption {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    flex-direction: row;
    display: flex;
  }

  &-loading-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    background-color: transparent;
    color: $recon-error !important;
  }

  @media (device-width <= 1024px) {
    .dropdown-menu {
      max-height: 150px;
      overflow-y: auto;
    }
  }

  .dropdown-menu {
    max-width: 375px;
    padding-top: 0;
    padding-bottom: 0.5rem;
    overflow: auto;
  }

  .Dropdown-btn.dropdown.caret-show {
    button {
      background: url("../chevron-down.svg") no-repeat;
    }
  }

  .Dropdown-btn.dropdown {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    text-overflow: ellipsis;

    .dropdown-toggle.btn {
      max-width: 100%;
      overflow: hidden;
      background-size: $icon-size $icon-size;
      background-color: transparent;
      background-position: right 7px;
      border: none;
      padding: 0 $right-padding 0 0 !important;
      font-size: 16px !important;
      box-shadow: none;
      text-transform: none;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      justify-content: center;

      .Dropdown-expand-icon {
        fill: $recon-dropdown-arrow-color;
        margin-left: 5px;
      }

      &::after {
        border: none;
      }

      &:active {
        background-color: transparent !important;
      }

      &:focus {
        box-shadow: none;
      }

      &:disabled {
        color: #212529 !important;
        opacity: 0.5;
        background-color: $light !important;
      }
    }
  }

  .Dropdown-option {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: #263238;
    padding: $spacing-sm 0;
    padding-left: $spacing-lg !important;

    &-check-icon {
      position: absolute;
      left: $spacing-sm;
    }

    &-text {
      margin-left: $spacing-lg;
      margin-right: $spacing-lg;
    }
  }
}

.SearchContainer {
  position: sticky;
  top: 0;
  background-color: white;
  padding-top: 0.5rem;
  z-index: 3;
}

.SrpLoader {
  background: rgb(38 50 56 / 12%);
  border-radius: 5px;
  width: 100%;
  padding: 4px 0;
  display: flex;
  justify-content: center;
  margin-bottom: -4px;

  .Dropdown-loading-indicator {
    border-width: 2px;
  }
}

.dropdown-menu {
  padding: 0;
  -ms-overflow-style: none;
}

.dropdown-menu::-webkit-scrollbar {
  display: none;
}

.VehicleCard-recon-inventory-tab-content {
  .DropdownButton-selectedOption > div {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  }
}

.VehicleCard-details-container {
  .DropdownButton-selectedOption > div {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  }
}

.UsersDropdown {
  .DropdownButton-selectedOption > div {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100em 0;
  }
}

.gradient-animated-background {
  animation-duration: 18s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: fff;
  background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
  backface-visibility: hidden;
}

.GradientLoader {
  border-radius: 5px;
  width: 100%;
  height: 12px;
  padding: 4px 0;
  display: flex;
  justify-content: center;
  margin-bottom: -4px;

  .Dropdown-loading-indicator {
    border-width: 2px;
  }
}
