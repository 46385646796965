@import "../../../scss/variables";
@import "../../../scss/theme/variables";

.recon-item-row {
  padding: $spacing-lg 24px;
}

.ReconInfo-title {
  @include VDPUpperCaseInfoTitle;
}

.ReconInfo-value {
  @include VDPInfoValue;

  color: #37474f;

  &.ReconInfo-value-negative {
    color: #d50000;
  }
}

.VelocityEngage {
  height: calc(100% - 64px);

  .VelocityEngage-tabs {
    margin-top: -48px;
    z-index: 990;

    @media screen and (width <= 576px) {
      margin-top: 0 !important;

      .Tabs-tabs-container {
        justify-content: flex-start !important;
        padding-left: 16px !important;
      }
    }

    .Tabs-tabs-container {
      justify-content: center;
      border-bottom: none !important;
    }

    .Tabs-content > div {
      height: calc(100%) !important;
    }

    &-margin {
      .Tabs-tabs-container {
        margin-left: 65px !important;
      }
    }
  }

  .PaneHeader-action-buttons {
    z-index: 991;
  }

  &-header-button {
    border: 1px solid $primary !important;
    padding: 7px 16px !important;
    margin-left: 16px;

    &:hover {
      border: 1px solid $primary !important;
    }

    &-icon {
      font-size: 13px !important;
      margin-left: 14px;
      margin-bottom: 1px;
    }

    .Button > button > div {
      font-style: normal !important;
      font-weight: bold !important;
      font-size: 14px !important;
      line-height: 18px !important;
      text-align: center !important;
      letter-spacing: 1px !important;
      text-transform: uppercase !important;
      color: $primary !important;
    }
  }

  .btn-secondary:hover,
  .btn-secondary:focus,
  .btn-secondary.focus,
  .btn-secondary:active,
  .btn-secondary:active:focus,
  .btn-secondary.active {
    border: 1px solid $primary !important;
  }

  &-more {
    fill: $muted !important;
    cursor: pointer;

    &-menu {
      cursor: pointer;
      position: absolute;
      right: 30px;
      bottom: -75px;
      padding: 8px 0;
      background: #fff;
      box-shadow: 0 8px 8px rgb(38 50 56 / 32%);
      border-radius: 3px;

      &-option {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.3px;
        color: $primary;
        margin: 8px 30px;
      }
    }
  }

  &-mobile-action-buttons {
    position: absolute;
    right: 16px;
    display: flex;
    flex-direction: row;

    &-button {
      fill: rgb(38 50 56 / 38%) !important;
      margin-left: 16px !important;
      font-size: 20px !important;
      z-index: 991;
    }
  }

  .btn-secondary:focus,
  .btn-secondary.focus,
  .btn-secondary:active,
  .btn-secondary:active:focus,
  .btn-secondary.active {
    border: 1px solid $primary !important;
  }
}
