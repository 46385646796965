@import "../../../../scss/variables";

.VehicleStepReportModal {
  tr {
    th {
      font-size: small;
      font-weight: $recon-bold;
      border: unset !important;
    }

    td {
      font-size: small;
      font-weight: $recon-medium;
      border: unset !important;
    }
  }

  .VehicleStepReportModal-alignLeft {
    text-align: left;
  }

  .VehicleStepReportModal-alignRight {
    text-align: right;
  }

  .VehicleStepReportModal-alignCenter {
    text-align: center;
  }

  .VehicleStepReportModal-scroller {
    overflow: auto;
    max-height: 400px;
    width: auto;
  }

  .modal-dialog {
    max-width: 90%;
  }

  .VehicleStepReportModal-titleRow {
    text-align: center;

    .VehicleStepReportModal-vehicleInfoTitle {
      background-color: lightgrey;
    }

    .VehicleStepReportModal-reconditionEffortTitle {
      background-color: darkgrey;
      color: white;
    }

    .VehicleStepReportModal-reconditionCoststTitle {
      background-color: lightskyblue;
    }
  }

  .VehicleStepReportModal-tableDataRow {
    .VehicleStepReportModal-stockNumber {
      text-decoration: underline;
      cursor: pointer;
    }

    .VehicleStepReportModal-good {
      color: $recon-ok;
    }

    .VehicleStepReportModal-bad {
      color: $recon-danger;
    }

    svg {
      vertical-align: middle;
    }

    svg.MuiSvgIcon-root {
      margin-right: 0.25em;
    }
  }

  .VehicleStepReportModal-darkGreyCell {
    background-color: whitesmoke;
  }

  .VehicleStepReportModal-lightGreyCell {
    background-color: #fbfbfb;
  }

  .VehicleStepReportModal-blueTableCell {
    background-color: #e7f6fe;
  }
}
