@import "scss/variables";

.SrpTagComponent-tag {
  display: flex;
  margin: 0 0 10px 10px;
  border-radius: 6px;
  height: 32px;
  align-items: center;
  white-space: nowrap;

  .TagComponent-tag-padding {
    padding: 0 $spacing-sm;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
