@import "../../../../../scss/theme/variables";

.EngagementsHeader-dropdown {
  margin-right: 28px;
  align-items: center;
  display: flex;
  justify-content: center;

  .DropdownButton-selectedOption {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    color: $muted;
    text-transform: capitalize;
  }

  .dropdown-toggle.btn {
    background-size: 10px 10px !important;
  }
}
