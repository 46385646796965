@import "../../../../scss/theme/variables";

.AppraisalInfoModalDesktop {
  .modal-dialog {
    width: 560px;
    max-width: none;
  }

  .modal-header {
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    box-shadow: inset 0 -1px 0 #e6e7e8;
  }

  .modal-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: $primary;
    text-transform: capitalize;
  }

  .modal-body {
    padding-top: 20px !important;
    padding-left: 30px !important;
  }
}

.AppraisalInfoModalMobile {
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translate3d(0, 100vh, 0);
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  overflow: scroll;
  background-color: $light;
  width: 100vw;
  height: calc(100% - 0px);
  padding-left: 16px;
  z-index: 9999;

  &-hide {
    transform: translate3d(0, 100vh, 0);
  }

  &-show {
    transform: translate3d(0, 0, 0);
    overflow: hidden;
  }

  &-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: $primary;
    text-transform: capitalize;
    justify-self: center;
  }

  &-title-row {
    position: relative;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
  }

  &-close {
    position: absolute;
    left: 0;
  }
}

.AppraisalInfoModalContent {
  display: flex;
  flex-direction: row;
  flex-grow: 1;

  &-row {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-basis: 100%;
  }

  &-field {
    margin-bottom: 24px;

    &-label {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: $muted;
      text-transform: uppercase;
    }

    &-value {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: $primary;
    }
  }
}
