@import "../../../scss/variables";

.FlatVDPPaneHeader {
  height: 48px;
  padding-left: 23px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: $recon-background-secondary;

  &-back-button {
    border: none;
    background-color: transparent;
    margin-left: -15px;
  }
}
