@import "../../../../scss/variables";
@import "../../../../scss/theme/variables";

.SelectContainer {
  position: relative;

  .Select {
    .Select__control {
      border: 1px solid $primary;
      border-radius: 3px;
      height: 56px;
      padding: 10px 12px;
    }

    .Select__dropdown-indicator {
      color: rgba($primary, 0.38);
      margin-top: -5px;
      padding-right: 0;
    }

    .Select__placeholder {
      font-size: 16px;
      letter-spacing: 0.3px;
      line-height: 24px;
    }
  }

  .floating-label {
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 24px;
    transition: 0.2s ease all;
    opacity: 0.5;
    background-color: transparent;
    padding: 0 2px;
    position: absolute;
    pointer-events: none;
    left: 15px;
    bottom: 15px;
  }

  .floating-label.transition {
    font-size: 12px;
    left: 11px;
    bottom: 43px;
    background-color: $recon-white;
    opacity: 1;
    color: $primary;
  }

  .floating-label-disabled {
    background: linear-gradient(
      to bottom,
      transparent 50%,
      hsl(0deg 0% 95%) 50%
    );
  }
}
