@import "../../../../scss/variables";

.CorporateReport {
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: unset;
  }

  .MuiAccordionSummary-content {
    margin: 0;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  .card {
    border: 1px solid rgb(0 0 0 / 12.5%);
  }

  .card-body {
    padding: 0;
  }

  .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-header {
    padding: 0;
    border-bottom: 0;
    margin-bottom: 0;
    background-color: unset;
  }

  .CorporateReport-overview {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .CorporateReport-header {
    display: flex;
    width: 100%;

    .TagFilter {
      margin-left: auto;
    }
  }

  .CorporateReport-title {
    font-weight: $recon-medium;
    font-size: 20px;
    padding: $spacing-md 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .CorporateReport-tag-filter {
    margin-left: 15px;
  }

  .CorporateReport-SummaryCard-container {
    border: #e0e0e0 solid thin;
    background-color: white;
    box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
    flex-grow: 1;
    margin: 5px;
    max-width: 340px;
    min-width: 140px;

    .SummaryCard-base-stat-value-text {
      font-size: 24px;
    }

    .CorporateReport-QuadrantCard {
      display: flex;
      flex-wrap: wrap;
      height: 100%;

      .CorporateReport-Quadrant {
        padding: 18px 10px;
        width: 50%;
        text-align: center;
        align-content: center;

        h4 {
          margin: 0 0 5px;
        }

        .quadrant-good {
          color: $recon-success;
        }

        .quadrant-bad {
          color: $recon-error;
        }

        .quadrant-neutral {
          color: $recon-secondary;
        }
      }
    }
  }
}
