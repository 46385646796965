@import "../../../scss/variables";

.MultiDownloadMenu-relative {
  position: relative;
}

.MultiDownloadMenu {
  position: absolute;
  right: 0;
  background-color: $recon-white;
  box-shadow: 0 8px 8px rgb(38 50 56 / 32%);
  border-radius: 3px;
  z-index: 1550 !important;
  padding: $spacing-lg;
  display: flex;
  flex-direction: column;
  min-width: 185px;
  border: 1px solid #dadada;

  &-option {
    background-color: $recon-white;
    border: none;
    margin-top: $spacing-md;
    margin-bottom: $spacing-md;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left !important;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: normal;

    &-icon {
      height: 22px !important;
      color: $recon-background-secondary;
    }

    &-text {
      text-transform: none;
      margin-left: $spacing-lg;
      color: $recon-background-secondary;
      vertical-align: middle;
    }
  }
}

.XLSIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $recon-white;
  width: 18px;
  height: 18px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 3px;
  text-align: center;
  vertical-align: middle;
  background-color: $recon-background-secondary;
  margin-left: 3px;
}

.DownloadMenu-download-button {
  margin-right: 15px;
  color: rgb(38 50 56 / 38%);
}

.MultiDownloadMenu-divider {
  border: 1px solid rgb(38 50 56 / 12%);
  width: 115%;
  margin: 0 0 0 -17px;
}
