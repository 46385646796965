@import "../../../scss/variables";

.ExtensionHeader {
  height: $header-height;
  z-index: 1500;

  .ExtensionHeader-Navbar {
    background-color: $recon-background-secondary;
    height: $header-height;
    padding: 0.4rem 16px;
    justify-content: center !important;

    .img-fluid {
      max-height: $header-img-height;
    }
  }
}
