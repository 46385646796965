@import "../../../../../scss/variables";

.EditReconInfoModal {
  .edit-recon-field {
    margin-bottom: 0 !important;

    .edit-recon-field-placeholder-appraised-value {
      top: 50px !important;
    }

    .edit-recon-field-placeholder-initial-cost {
      top: 131px !important;
    }

    .edit-recon-field-placeholder-estimated-recon {
      top: 212px !important;
    }
  }

  .modal-body {
    padding-top: 0;
  }

  div.modal-sub-title {
    display: block;
    margin-bottom: 0 !important;
    margin-top: 8px !important;
  }

  .EditReconInfoModal-message-container {
    font-size: 14px !important;
  }
}
