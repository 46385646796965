@import "../../../../scss/theme/variables";

.VelocityEngageOverviewTab-desktop {
  padding: 24px 19px;
  background-color: rgb(251 251 251);
  overflow-y: auto;

  .highlights {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
  }

  .content {
    display: flex;
    flex-direction: row;

    .engage-info {
      margin-right: 16px;
    }
  }
}

.VelocityEngageOverviewTab-tablet {
  .highlights {
    background-image: url("../../../../Images/u111_state0.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 40px;
  }
}

.VelocityEngageOverviewTab-mobile {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;

  .highlights {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    flex-shrink: 0;
  }
}

.VelocityEngageOverviewTab-divider {
  border: 1px solid rgb(38 50 56 / 12%);
  margin-top: 19px;
  margin-bottom: 19px;
}
