@import "../../../../scss/variables";

.successTint {
  color: $recon-success !important;
}

.defaultTint {
  color: $recon-background-secondary;
  fill: $recon-background-secondary;
}

.grayTint {
  color: $recon-background-secondary;
  fill: $recon-background-secondary;
}

.history-alert-chip {
  height: 24px;
  border-radius: 12px;
  padding: 0 8px;
  margin-right: $spacing-lg;
  margin-left: 32px;
  display: flex;
  flex-direction: row;
  background: $recon-success;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
}

.HistoryItem-container {
  display: flex;
  flex-direction: row;
  min-height: fit-content;
  align-items: stretch;
  margin-left: $spacing-slg;
}

.HistoryItem-labelChipContainer {
  flex: 1;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}

.mobile-viewport .HistoryItem-labelChipContainer {
  flex-direction: column;
}

.HistoryItem-IconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6px;
  width: 24px;
  margin-left: 10px;
  margin-right: $spacing-slg;

  .HistoryItem-divider {
    flex-grow: 1;
    margin-top: $spacing-lg;
    margin-bottom: $spacing-md;
    min-height: 32px;
    width: 2px;
    background: rgb(38 50 56 / 12%);
  }
}

.HistoryItem-LastOneIconContainer {
  height: 60px;
}

.HistoryItem-labelsContainer {
  display: flex;
  padding-right: $spacing-xl;
  margin-bottom: $spacing-lg;
  flex-direction: column;
  color: $recon-background-secondary;

  .HistoryItem-label {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    color: $recon-background-secondary;
    padding-bottom: $spacing-md;
    display: flex;
  }
}

.HistoryItem-linkContainer {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: $recon-background-secondary;

  .HistoryItem-link {
    text-decoration: underline;
  }

  .HistoryItem-time {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $recon-background-secondary;
  }
}

.HistoryItem-container:last-child {
  .HistoryItem-IconContainer {
    .HistoryItem-divider {
      display: none !important;
    }
  }
}
