@import "scss/variables";
@import "scss/theme/variables";

.notes-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: unset;

    &-pin-button {
      background-color: transparent;
      border: none;
      padding: 0 !important;
    }
  }

  &-content {
    display: flex;
    flex: 1;
    flex-grow: 1;
    padding: 0 $spacing-lg $spacing-lg $spacing-lg;
    overflow: hidden auto;
    border-top: 1px solid #dee2e6;
    background-color: #fff;
  }
}

.notes-container.mobile {
  // nasty this is happening on all vdp screen tabs
  height: calc(100% - 74px - 40px);

  .VehicleCardNotes-notes {
    padding-bottom: 40px;
  }

  .VehicleCardNotes-inputContainer {
    position: fixed;
    bottom: 4px;
    left: 4px;
    right: 4px;
    background-color: #fff;
  }
}
