@import "../../../../scss/variables";
@import "../../../../scss/theme/variables";
@import "../FlatTasks";

.SelectedTotals {
  display: grid;
  grid-template-areas: ". . . . . totals-label totals-label total-hours labor-price parts-price total-price .";
  grid-template-columns: $grid-template-column-widths;
  background-color: $recon-background-tertiary;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-top: $spacing-md;

  & > div {
    display: flex;
    align-items: center;
  }

  .SelectedTotals-hours-label {
    grid-area: total-hours-label;
    display: none;
  }

  .SelectedTotals-hours {
    grid-area: total-hours;
  }

  .SelectedTotals-labor-label {
    grid-area: labor-label;
    display: none;
  }

  .SelectedTotals-labor {
    grid-area: labor-price;
  }

  .SelectedTotals-parts-label {
    grid-area: parts-label;
    display: none;
  }

  .SelectedTotals-parts {
    grid-area: parts-price;
  }

  .SelectedTotals-label {
    grid-area: totals-label;
    justify-content: flex-end;
    margin-right: $spacing-lg;
  }

  .SelectedTotals-total {
    grid-area: total-price;
  }

  @media screen and (max-width: $screen-vdp-ipad) {
    grid-template-columns: 0.5fr 2fr max-content repeat(5, 1fr);
    grid-template-areas: ". . . totals-label total-hours labor-price parts-price total-price";
  }

  @media screen and (max-width: $screen-sm-min) {
    grid-template-columns: 0.5fr 2fr max-content repeat(5, 1fr);
    grid-template-areas: ". . . totals-label total-hours labor-price parts-price total-price";
  }
}

.SelectedTotals-padding-right {
  padding-right: 17px;
}

.SelectedTotals-pinned {
  grid-template-columns: 0.5fr 1.25fr 0.75fr 0.75fr 1fr;
  grid-template-areas:
    ". . . total-hours-label total-hours"
    ". . . labor-label labor-price"
    ". . . parts-label parts-price"
    ". totals-label totals-label totals-label total-price";

  .SelectedTotals-hours-label,
  .SelectedTotals-labor-label,
  .SelectedTotals-parts-label {
    display: inline;
  }
}
