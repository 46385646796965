@import "scss/variables";

.carfax {
  &-report {
    width: 100%;
    height: 100%;
  }

  &-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 2%;
    padding-right: 2%;
    border-bottom: 1px solid $recon-divider-color;

    .controls {
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }

  &-logout {
    color: white !important;
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  gap: 5px;
  height: 100%;
  margin: 0 auto;
}

.controls-mobile {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  padding-left: 0%;
  padding-right: 4%;
  border-bottom: 1px solid $recon-divider-color;
  margin: 0 auto;
  gap: 5px;
}

.purchase-report {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}
