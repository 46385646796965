@import "scss/variables";

.notifications-link {
  cursor: pointer;
  padding: 0 $spacing-sm;

  svg {
    font-size: 26px;
  }

  .notifications-icon {
    color: $recon-white;
  }

  .MuiBadge-badge {
    font-weight: bold;
    color: #fff;
    background: $recon-primary;
    border: $recon-background-secondary solid 1px;
  }

  .MuiBadge-anchorOriginTopRightRectangle {
    transform: scale(0.95) translate(45%, -05%);
  }
}
