@import "../../../../scss/variables";
@import "../../../../scss/theme/variables";
@import "../FlatTasks";

.TaskTableHeader {
  display: grid;
  grid-template-areas: "checkbox status task description assignee photos rate hours labor parts total .";
  grid-template-columns: $grid-template-column-widths;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $recon-background-secondary;
  border-bottom: 1px solid rgb(38 50 56 / 12%);

  & > div {
    display: flex;
    align-items: center;
  }

  .MuiSvgIcon-root {
    fill: $muted;
  }

  .checkbox-col {
    grid-area: checkbox;
    justify-content: center;

    .checkbox-container {
      margin: 0;
      padding: 0;
      padding-left: 1px;
      max-width: 25px;

      .p-2 {
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }

  .status-col {
    grid-area: status;
  }

  .task-col {
    grid-area: task;
  }

  .description-col {
    grid-area: description;
  }

  .assignee-col {
    grid-area: assignee;
  }

  .photos-col {
    grid-area: photos;
  }

  .rate-col {
    grid-area: rate;
  }

  .hours-col {
    grid-area: hours;
  }

  .labor-col {
    grid-area: labor;
  }

  .parts-col {
    grid-area: parts;
  }

  .total-col {
    grid-area: total;
  }

  @media screen and (max-width: $screen-vdp-ipad) {
    grid-template-columns: 0.5fr 2fr max-content repeat(5, 1fr);
    grid-template-areas: ". . . rate hours labor parts total";

    .status-col,
    .task-col,
    .description-col,
    .assignee-col,
    .photos-col {
      display: none;
    }
  }
}

.TaskTableHeader-pinned {
  grid-template-columns: 0.5fr 1.25fr 0.75fr 0.75fr 1fr;
  grid-template-areas: ". . rate hours total";

  .status-col,
  .task-col,
  .description-col,
  .assignee-col,
  .photos-col,
  .labor-col,
  .parts-col {
    display: none;
  }
}

.TaskTableHeader-padding-right {
  padding-right: 17px;
}
