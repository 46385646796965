@import "../../../scss/variables";

$location-marker-size: 54px;

.location-container {
  height: 100% !important;
}

.location-header {
  height: 10%;
}

.location-desktop-header {
  background-color: $recon-background-tertiary !important;
}

.location-marker {
  position: relative;
  height: $location-marker-size;
  transform: translate(0, -50%);

  > * {
    position: absolute;
    transform: translate(-50%, -50%);
  }
}

.location-marker-icon {
  height: $location-marker-size !important;
  width: $location-marker-size !important;
}

.location-marker-icon-outline {
  height: $location-marker-size + 4px !important;
  width: $location-marker-size + 4px !important;
  color: #fff;
}

.location-marker-icon-shadow {
  top: 4px;
  height: $location-marker-size !important;
  width: $location-marker-size !important;
  color: #000;
  opacity: 0.7;
}

.location-container.Location-flat {
  .PaneHeader {
    height: 49px;
    min-height: 49px;
    max-height: 49px;
  }
}
