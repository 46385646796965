@import "../../../scss/variables";

.Recalls-empty {
  font-size: 16px;
  line-height: 24px;
}

.Recalls-pin-button {
  border: none;
  background-color: transparent;
  padding: 0 !important;
}

.Recalls {
  padding: 0 !important;

  .Recalls > .container {
    padding-bottom: 115px;
  }

  .PaneHeader {
    padding-left: 23px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 49px !important;
    min-height: 49px !important;

    &-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.3px;
      color: $recon-background-secondary;
    }
  }

  .flex-grow.vertical-scroll.container {
    margin-left: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: 100% !important;
    max-width: none !important;

    .accordion {
      border-bottom: 1px solid #e6e7e8;
      max-width: 1056px !important;
    }

    .RecallItem-card {
      border: none !important;
      margin-bottom: 0 !important;

      button {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }

      .RecallItem-header {
        padding-left: 26px !important;

        .RecallItem-warning-icon {
          color: $recon-background-secondary;
        }

        .RecallItem-header-value {
          text-transform: none !important;
        }
      }

      .RecallItem-body {
        padding-left: 26px !important;
        padding-top: 0 !important;
      }
    }
  }
}
