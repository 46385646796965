.ConditionFilter {
  display: flex;
  align-items: center;
  margin-left: 15px;
  white-space: nowrap;

  .ConditionFilter-select-dropdown {
    padding: 5px 0 5px 10px;
    max-width: 210px;

    &-selected {
      color: rgb(38 50 56 / 60%);
    }
  }
}
