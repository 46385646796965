@import "../../../../../scss/theme/variables";

.HighlightCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 246px;
  height: 96px;
  padding: 16px 24px;
  margin: 16px 0;
  font-style: normal;
  color: $primary;
  background: #fff;
  box-shadow: 0 4px 8px rgb(0 0 0 / 8%);
  border-radius: 5px;

  // tablet
  @media screen and (width <= 1000px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 230px;
    height: 52px;
    padding: 14px 10px;
  }

  // mobile
  @media screen and (width <= 575px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 168px;
    height: 75px;
    padding: 8px 16px;
  }

  .value {
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.5px;

    // tablet
    @media screen and (width <= 1000px) {
      font-size: 18px;
      line-height: 23px;
    }

    @media screen and (width <= 575px) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.3px;

    // tablet
    @media screen and (width <= 1000px) {
      font-size: 13px;
      text-align: right;
    }

    @media screen and (width <= 575px) {
      font-size: 16px;
      line-height: 24px;
      white-space: nowrap;
    }
  }
}
