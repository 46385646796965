@import "../../../../scss/variables";

.VehicleLog {
  &-section-header {
    vertical-align: middle;
    padding: $spacing-slg;
    padding-left: 0;
    padding-right: 0;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: $recon-alt-text;

    &:first-child {
      padding-top: 0 !important;
    }
  }

  .HistoryItem-container {
    margin-left: 0;
  }

  /*
    Pick all the rows that contain a history item (i.e. those that aren't rows for section headers)
    Of those, pick only the rows that are immediately followed by a row which also contains a history item
    And make the history item divider (of the first / previous sibling) visible

    This will show the dividers only for items followed by other items.
    While they remain hidden when it's the last item in the list, or an item followed by a section header
  */
  .VehicleLogRow:has(.HistoryItem-container) {
    &:has(+ .VehicleLogRow > .HistoryItem-container) {
      .HistoryItem-IconContainer .HistoryItem-divider {
        display: block !important;
      }
    }
  }
}
