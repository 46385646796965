@import "../../../scss/variables";
@import "../../../scss/mixins";

.Tabs {
  .Dropdown-expand-icon {
    padding-bottom: 0 !important;
    margin-top: 2px;
  }

  &-dropdown {
    display: flex;
  }

  &-tabs-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid $recon-alt-text-12;

    .Tabs-tab-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-right: 20px;
      padding-top: 10px;

      .Tabs-tab {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0.15px;
        color: $recon-alt-text;
        background-color: transparent;
        text-transform: none;
        border: none;
        padding-bottom: 10px;
        padding-left: 0;
        padding-right: 0;
        border-bottom: 2px solid transparent;
      }

      .Tabs-tab.active {
        color: $recon-background-secondary;
      }

      .Tabs-tab.disabled {
        color: $recon-alt-text-12;
      }

      .Tabs-underline {
        width: 97%;
        border-bottom: 2px solid transparent;
      }

      .Tabs-underline.active {
        border-bottom: 2px solid $recon-error;
      }
    }
  }
}
