@import "../../../../scss/variables";

.success {
  background-color: $recon-success !important;
}

.error {
  background-color: $recon-error !important;
}

.info {
  background-color: $recon-info !important;
}

.warning {
  background-color: $recon-warning !important;
}

.icon {
  font-size: 20px;
}

.icon-variant {
  opacity: 0.9;
  margin-right: $base-margin;
}

.message {
  display: flex;
  align-items: center;
  max-width: 600px;
}
