@import "../../../../../../../scss/variables";
@import "../../../../../../../scss/theme/variables";

.VehicleCardTabFooter-container {
  display: flex;

  .VehicleCardTabFooter-badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    opacity: 0.6;
    transition: 0.5s opacity;
    padding: 0;
    margin-right: 12px;
    text-transform: uppercase;

    .MuiBadge-badge {
      font-weight: $recon-bold;
      color: $recon-white;
      background: $recon-primary;
    }

    .MuiBadge-anchorOriginTopRightRectangle {
      top: unset;
      right: unset;
    }

    .VehicleCardTabFooter-badge-icon {
      height: 24px;
    }

    .engage-btn-icon {
      height: 24px;
    }

    &:hover {
      opacity: 1;
    }
  }

  .VehicleCardTabFooter-Inventory {
    display: flex;
  }

  .VehicleCardTabFooter-children {
    display: flex;
    flex-grow: 1;
    overflow: hidden;

    .VehicleCardTabFooter-Engage-buttons {
      display: flex;

      .VehicleCardTabFooter-badge {
        flex-direction: column-reverse;
      }
    }
  }
}
