@import "../../../../../scss/variables";

.SortSelector {
  .sort-select {
    margin: 0;
  }

  .btn-light,
  .btn-light:focus {
    font-weight: $recon-bold;
    background-color: unset;
    border-color: #ebeeee;
  }

  .dropdown-toggle::after {
    vertical-align: 0.2em;
    margin-left: 0.5em;
  }
}
